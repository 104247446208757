import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get tax receipt calender configuration
export const getTaxReceiptCalenderConfiguration = createAsyncThunk(
  "taxreceipt-calender/getTaxReceiptCalenderConfiguration",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/taxReceipt/setting?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}&year=${singleObj.year}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// add new setting
export const addNewSetting = createAsyncThunk(
  "taxReceiptCalender/addNewSetting",
  async (singleObj) => {
    try {
      const data = {
        year: singleObj.year,
        startDate: singleObj.startDate,
        endDate: singleObj.endDate,
        lastDate: singleObj.lastDate,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/taxReceipt/setting`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
      };
    }
  }
);

// update setting
export const updateSetting = createAsyncThunk(
  "taxReceiptCalender/updateSetting",
  async (singleObj) => {
    try {
      const data = {
        year: singleObj.year,
        startDate: singleObj.startDate,
        endDate: singleObj.endDate,
        lastDate: singleObj.lastDate,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/taxReceipt/setting/${singleObj.tagNumber}?_method=PUT`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// delete setting
export const deleteSetting = createAsyncThunk(
  "taxReceiptCalender/deleteSetting",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/taxReceipt/setting/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }

      return {
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

const taxReceiptCalenderSlice = createSlice({
  name: "taxreceipt-calender",
  initialState: {
    // taxreceipt calender configuration
    taxReceiptCalenderConfigurationLoading: false,
    taxReceiptCalenderConfigurationData: null,
    taxReceiptCalenderConfigurationError: null,
    // add new setting
    newSettingLoading: false,
    newSettingData: null,
    // update setting
    updateSettingLoading: false,
    updateSettingData: null,
    // delete setting
    deleteSettingLoading: false,
    deleteSettingData: null,
  },
  reducers: {
    clearNewSettingStates: (state) => {
      state.newSettingData = null;
    },
    clearEditSettingStates: (state) => {
      state.updateSettingData = null;
    },
    clearDeleteSettingStates: (state) => {
      state.deleteSettingData = null;
    },
  },
  extraReducers: (builder) => {
    // taxreceipt calender configuration
    builder
      .addCase(getTaxReceiptCalenderConfiguration.pending, (state) => {
        state.taxReceiptCalenderConfigurationLoading = true;
        state.taxReceiptCalenderConfigurationData = null;
        state.taxReceiptCalenderConfigurationError = null;
      })
      .addCase(
        getTaxReceiptCalenderConfiguration.fulfilled,
        (state, action) => {
          state.taxReceiptCalenderConfigurationLoading = false;
          state.taxReceiptCalenderConfigurationData = action.payload;
          state.taxReceiptCalenderConfigurationError = null;
        }
      )
      .addCase(getTaxReceiptCalenderConfiguration.rejected, (state, action) => {
        state.taxReceiptCalenderConfigurationLoading = false;
        state.taxReceiptCalenderConfigurationData = null;
        state.taxReceiptCalenderConfigurationError = action.error.message;
      })
      // add new setting
      .addCase(addNewSetting.pending, (state) => {
        state.newSettingLoading = true;
        state.newSettingData = null;
      })
      .addCase(addNewSetting.fulfilled, (state, action) => {
        state.newSettingLoading = false;
        state.newSettingData = action.payload;
        const { data } = action.payload;

        if (data) {
          // Add the new data to the top of the array
          state.taxReceiptCalenderConfigurationData.data.unshift(data);
        }
      })
      // update setting
      .addCase(updateSetting.pending, (state) => {
        state.updateSettingLoading = true;
        state.updateSettingData = null;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.updateSettingLoading = false;
        state.updateSettingData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const index =
            state.taxReceiptCalenderConfigurationData.data.findIndex(
              (data) => data.tagNumber === tagNumber
            );
          if (index !== -1) {
            state.taxReceiptCalenderConfigurationData.data[index] = data;
          }
        }
      })
      // delete setting
      .addCase(deleteSetting.pending, (state) => {
        state.deleteSettingLoading = true;
        state.deleteSettingData = null;
      })
      .addCase(deleteSetting.fulfilled, (state, action) => {
        state.deleteSettingLoading = false;
        state.deleteSettingData = action.payload;
        const { successMsg, tagNumber } = action.payload;

        if (successMsg) {
          state.taxReceiptCalenderConfigurationData.data =
            state.taxReceiptCalenderConfigurationData.data.filter(
              (data) => data.tagNumber !== tagNumber
            );
        }
      });
  },
});

export default taxReceiptCalenderSlice.reducer;
export const {
  clearNewSettingStates,
  clearEditSettingStates,
  clearDeleteSettingStates,
} = taxReceiptCalenderSlice.actions;
