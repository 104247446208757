/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../CSS/UI/Sidebar.css";
import { MetroSpinner } from "react-spinners-kit";
import Icon from "react-icons-kit";
import { arrows_right } from "react-icons-kit/linea/arrows_right";
import { arrows_down } from "react-icons-kit/linea/arrows_down";
import { image } from "react-icons-kit/feather/image";
import { layout } from "react-icons-kit/feather/layout";
import { logOut } from "react-icons-kit/feather/logOut";
import { trendingUp } from "react-icons-kit/feather/trendingUp";
import { users } from "react-icons-kit/feather/users";
import { userCheck } from "react-icons-kit/feather/userCheck";
import { droplet } from "react-icons-kit/feather/droplet";
import { dollarSign } from "react-icons-kit/feather/dollarSign";
import { barChart2 } from "react-icons-kit/feather/barChart2";
import { creditCard } from "react-icons-kit/feather/creditCard";
import { refreshCcw } from "react-icons-kit/feather/refreshCcw";
import { checkSquare } from "react-icons-kit/feather/checkSquare";
import { settings } from "react-icons-kit/feather/settings";
import { database } from "react-icons-kit/feather/database";
import { globe } from "react-icons-kit/feather/globe";
import { briefcase } from "react-icons-kit/feather/briefcase";
import { calendar } from "react-icons-kit/feather/calendar";
import { clipboard } from "react-icons-kit/feather/clipboard";
import { smartphone } from "react-icons-kit/feather/smartphone";
import { bell } from "react-icons-kit/feather/bell";
import { x } from "react-icons-kit/feather/x";
import { check } from "react-icons-kit/feather/check";
import { compass } from "react-icons-kit/feather/compass";
import { share2 } from "react-icons-kit/feather/share2";
import { thumbsUp } from "react-icons-kit/feather/thumbsUp";
import { flag } from "react-icons-kit/feather/flag";
import { useDispatch, useSelector } from "react-redux";
import {
  setFundraisingDropdown,
  setFundraisingDropdownHeight,
  setManageDropdown,
  setManageDropdownHeight,
  setIntegrationHubDropdown,
  setIntegrationHubDropdownHeight,
} from "../../Store/Slices/Sidebar/SidebarSlice";
import { getUnreadNotifications } from "../../Store/Slices/Sidebar/Notifications/NotificationsSlice";
import Tippy from "@tippyjs/react";
import axios from "axios";
import hostName from "../../config";

// get user
function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  } else {
    user = null;
  }
  return user;
}

// get organization
function getOrganization() {
  let org = localStorage.getItem("organization");
  if (org) {
    org = JSON.parse(org);
  } else {
    org = null;
  }
  return org;
}

const Sidebar = ({ sidebarToggled }) => {
  // state
  const [user] = useState(getUser());

  // selected org
  const [selectedOrganization] = useState(getOrganization());

  // dispatch
  const dispatch = useDispatch();

  // sidebar redux states
  const {
    fundraisingDropdown,
    fundraisingDropdownHeight,
    manageDropdown,
    manageDropdownHeight,
    integrationHubDropdown,
    integrationHubDropdownHeight,
  } = useSelector((state) => state.sidebar);

  // notifications redux state
  const {
    unreadNotificationsLoading,
    totalUnreadNotifications,
    unreadNotificationsError,
  } = useSelector((state) => state.notifications);

  // get unread notification if user else navigate to the login page
  useEffect(() => {
    // If the effect has already run, exit early
    if (totalUnreadNotifications !== null) return;

    if (user) {
      dispatch(getUnreadNotifications({ token: user.token }))
        .unwrap()
        .catch((err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    } else {
      navigate("/login");
    }
  }, [user]);

  // useRef
  const fundraisingDropdownRef = useRef(null);
  const manageDropdownRef = useRef(null);
  const integrationHubDropdownRef = useRef(null);

  // setting fundraising dropdown height
  useEffect(() => {
    if (fundraisingDropdown) {
      dispatch(
        setFundraisingDropdownHeight(
          `${fundraisingDropdownRef.current.scrollHeight}px`
        )
      );
    } else {
      dispatch(setFundraisingDropdownHeight("0px"));
    }
  }, [fundraisingDropdown]);

  // setting manage dropdown height
  useEffect(() => {
    if (manageDropdown) {
      dispatch(
        setManageDropdownHeight(`${manageDropdownRef.current.scrollHeight}px`)
      );
    } else {
      dispatch(setManageDropdownHeight("0px"));
    }
  }, [manageDropdown]);

  // setting integration hub dropdown height
  useEffect(() => {
    if (integrationHubDropdown) {
      dispatch(
        setIntegrationHubDropdownHeight(
          `${integrationHubDropdownRef.current.scrollHeight}px`
        )
      );
    } else {
      dispatch(setIntegrationHubDropdownHeight("0px"));
    }
  }, [integrationHubDropdown]);

  // navigate
  const navigate = useNavigate();

  // loader state
  const [loader, setLoader] = useState(false);

  // handle logout
  const handleLogout = () => {
    setLoader(true);
    axios
      .post(
        `${hostName}api/admin/v1/auth/logout`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(() => {
        localStorage.removeItem("user");
        navigate("/login");
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setLoader(false));
  };

  return (
    <div className={!sidebarToggled ? "sidebar" : "sidebar hidden"}>
      <div className="sidebar-links-container">
        <NavLink
          to="/dashboard"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={layout} size={20} style={{ marginRight: 7 + "px" }} />
          Dashboard
        </NavLink>
        <NavLink
          to="/analytics"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={trendingUp} size={20} style={{ marginRight: 7 + "px" }} />
          Analytics
        </NavLink>
        <NavLink
          to="/campaigns"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={droplet} size={20} style={{ marginRight: 7 + "px" }} />
          Campaigns
        </NavLink>
        <NavLink
          to="/donors"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={users} size={20} style={{ marginRight: 7 + "px" }} />
          Donors
        </NavLink>
        {/* fundraising dropdown */}
        <div
          className={
            fundraisingDropdown ? "sidebar-link white" : "sidebar-link"
          }
          onClick={() => dispatch(setFundraisingDropdown(!fundraisingDropdown))}
        >
          <Icon icon={dollarSign} size={20} style={{ marginRight: 7 + "px" }} />
          Fundraising
          {fundraisingDropdown ? (
            <Icon icon={arrows_down} size={16} className="arrow-icon" />
          ) : (
            <Icon icon={arrows_right} size={16} className="arrow-icon" />
          )}
        </div>
        <div
          className={`dropdown-content ${fundraisingDropdown ? "open" : ""}`}
          ref={fundraisingDropdownRef}
          style={{
            maxHeight: fundraisingDropdownHeight,
          }}
        >
          <NavLink to="/fundraising/transactions" className="dropdown-link">
            <Icon
              icon={creditCard}
              size={16}
              style={{ marginRight: 7 + "px" }}
            />
            TRANSACTIONS
          </NavLink>
          <NavLink
            to="/fundraising/recurring-payments"
            className="dropdown-link"
            style={{
              pointerEvents:
                selectedOrganization.gatewayNodeTag === 0 ? "none" : "auto",
              opacity: selectedOrganization.gatewayNodeTag === 0 ? 0.5 : 1,
            }}
          >
            <Icon
              icon={refreshCcw}
              size={16}
              style={{ marginRight: 7 + "px" }}
            />
            RECURRING PAYMENTS
          </NavLink>
          <NavLink
            to="/fundraising/echeck"
            className="dropdown-link"
            style={{
              pointerEvents:
                selectedOrganization.eCheckNodeTag === 0 ? "none" : "auto",
              opacity: selectedOrganization.eCheckNodeTag === 0 ? 0.5 : 1,
            }}
          >
            <Icon
              icon={checkSquare}
              size={16}
              style={{ marginRight: 7 + "px" }}
            />
            eCHECK
          </NavLink>
          {/* <NavLink to="/fundraising/pledges" className="dropdown-link">
            <Icon icon={flag} size={16} style={{ marginRight: 7 + "px" }} />
            PLEDGES
          </NavLink> */}
        </div>
        {/* manage dropdown */}
        <div
          className={manageDropdown ? "sidebar-link white" : "sidebar-link"}
          onClick={() => dispatch(setManageDropdown(!manageDropdown))}
        >
          <Icon icon={settings} size={20} style={{ marginRight: 7 + "px" }} />
          Manage
          {manageDropdown ? (
            <Icon icon={arrows_down} size={16} className="arrow-icon" />
          ) : (
            <Icon icon={arrows_right} size={16} className="arrow-icon" />
          )}
        </div>
        <div
          className={`dropdown-content ${manageDropdown ? "open" : ""}`}
          ref={manageDropdownRef}
          style={{
            maxHeight: manageDropdownHeight,
          }}
        >
          <NavLink to="/manage/data" className="dropdown-link">
            <Icon icon={database} size={16} style={{ marginRight: 7 + "px" }} />
            DATA
          </NavLink>
          <NavLink to="/manage/channels" className="dropdown-link">
            <Icon icon={globe} size={16} style={{ marginRight: 7 + "px" }} />
            CHANNELS
          </NavLink>
          <NavLink to="/manage/organization" className="dropdown-link">
            <Icon
              icon={briefcase}
              size={16}
              style={{ marginRight: 7 + "px" }}
            />
            ORGANIZATION
          </NavLink>
          <NavLink to="/manage/taxreceipt-calender" className="dropdown-link">
            <Icon icon={calendar} size={16} style={{ marginRight: 7 + "px" }} />
            TAX RECEIPT CALENDER
          </NavLink>
          <NavLink to="/manage/taxreceipts" className="dropdown-link">
            <Icon
              icon={clipboard}
              size={16}
              style={{ marginRight: 7 + "px" }}
            />
            TAX RECEIPTS
          </NavLink>
          <NavLink to="/manage/etransfer" className="dropdown-link">
            <Icon
              icon={smartphone}
              size={16}
              style={{ marginRight: 7 + "px" }}
            />
            eTRANSFER
          </NavLink>
          <NavLink to="/manage/pledge-control" className="dropdown-link">
            <Icon icon={thumbsUp} size={16} style={{ marginRight: 7 + "px" }} />
            PLEDGE CONTROL
          </NavLink>
        </div>
        {/* integration hub dropdown */}
        <div
          className={
            integrationHubDropdown ? "sidebar-link white" : "sidebar-link"
          }
          onClick={() =>
            dispatch(setIntegrationHubDropdown(!integrationHubDropdown))
          }
        >
          <Icon icon={share2} size={20} style={{ marginRight: 7 + "px" }} />
          INTEGRATION HUB
          {integrationHubDropdown ? (
            <Icon icon={arrows_down} size={16} className="arrow-icon" />
          ) : (
            <Icon icon={arrows_right} size={16} className="arrow-icon" />
          )}
        </div>
        <div
          className={`dropdown-content ${integrationHubDropdown ? "open" : ""}`}
          ref={integrationHubDropdownRef}
          style={{
            maxHeight: integrationHubDropdownHeight,
          }}
        >
          <NavLink to="/integration-hub/app-explorer" className="dropdown-link">
            <Icon icon={compass} size={16} style={{ marginRight: 7 + "px" }} />
            APP EXPLORER
          </NavLink>
        </div>
        <NavLink
          to="/reports"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={barChart2} size={20} style={{ marginRight: 7 + "px" }} />
          Reports
        </NavLink>
        <NavLink
          to="/media-center"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={image} size={20} style={{ marginRight: 7 + "px" }} />
          Media Center
        </NavLink>
        <NavLink
          to="/users"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={userCheck} size={20} style={{ marginRight: 7 + "px" }} />
          Users
        </NavLink>
        <NavLink
          to="/notifications"
          className={
            fundraisingDropdown || manageDropdown
              ? "sidebar-link transparent"
              : "sidebar-link"
          }
        >
          <Icon icon={bell} size={20} style={{ marginRight: 7 + "px" }} />
          Notifications
          <div
            className={`custom-notification-badge ${
              unreadNotificationsError ||
              (!unreadNotificationsLoading && totalUnreadNotifications === null)
                ? "bg-red"
                : "bg-green"
            }`}
          >
            {unreadNotificationsLoading ? (
              <MetroSpinner
                loading={unreadNotificationsLoading}
                size={12}
                color="#fff"
              />
            ) : (
              <>
                {unreadNotificationsError ? (
                  <Tippy content={unreadNotificationsError}>
                    <span>
                      <Icon icon={x} size={12} color="#fff" />
                    </span>
                  </Tippy>
                ) : (
                  <>
                    {totalUnreadNotifications === null ? (
                      <Tippy content={`Null`}>
                        <span>
                          <Icon icon={x} size={14} color="#fff" />
                        </span>
                      </Tippy>
                    ) : (
                      <>
                        {totalUnreadNotifications === 0 ? (
                          <Tippy content={`All caught up`}>
                            <span>
                              <Icon icon={check} size={14} color="#fff" />
                            </span>
                          </Tippy>
                        ) : (
                          <>
                            {totalUnreadNotifications > 0 &&
                            totalUnreadNotifications <= 99 ? (
                              <>{totalUnreadNotifications}</>
                            ) : (
                              <>
                                {totalUnreadNotifications > 99 &&
                                totalUnreadNotifications <= 999 ? (
                                  "99+"
                                ) : (
                                  <>
                                    {totalUnreadNotifications > 999 && "999+"}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </NavLink>
      </div>

      {/* logout */}
      <div className="logout-div">
        <div className="user-circle">
          {user.firstName.charAt(0)}
          {user.lastName.charAt(0)}
        </div>
        <div>
          {loader ? (
            <MetroSpinner loading={loader} size={20} color="#000" />
          ) : (
            <Tippy content="Logout">
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  outline: "none",
                  background: "transparent",
                }}
                onClick={handleLogout}
                disabled={loader}
              >
                <Icon icon={logOut} size={20} />
              </button>
            </Tippy>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
