import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import hostName from "../../../../config";

// get pledges transaction records
export const getPledgesTransactionRecords = createAsyncThunk(
  "pledge/getPledgesTransactionRecords",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/pledge?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// get pledges data
export const getPledgesData = createAsyncThunk(
  "pledge/getPledgesData",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/pledgeHeader?page=${singleObj.page}&pageSize=${singleObj.pageSize}&order=${singleObj.order}&by=${singleObj.by}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter pledge records
export const filterPledgeRecords = createAsyncThunk(
  "pledge/filterPledgeRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = ["campaign_tag", "name", "description", "by"];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/pledgeHeader?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// delete image
export const deleteImage = createAsyncThunk(
  "pledge/deleteImage",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.patch(
        `${hostName}api/admin/v1/pledgeHeader/${singleObj.tagNumber}`,
        { imageField: singleObj.imageField }, // Request body
        axiosConfig // Axios configuration (e.g., headers, auth)
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        const { messageDetails } = error.response.data;

        // Check if messageDetails is an array with a single string
        if (Array.isArray(messageDetails) && messageDetails.length > 0) {
          apiError = messageDetails[0]; // Take the first string in the array
        } else {
          apiError = messageDetails;
        }
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add new pledge
export const addNewPledge = createAsyncThunk(
  "pledge/addNewPledge",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data", // Set the Content-Type header to 'multipart/form-data'
        },
      };

      const formData = new FormData();
      formData.append("name", singleObj.name);
      formData.append("description", singleObj.description);
      formData.append("campaignTag", singleObj.campaignTag);
      formData.append("targetCount", singleObj.targetCount);
      formData.append("unitAmount", singleObj.unitAmount);
      formData.append("targetAmount", singleObj.targetAmount);
      if (singleObj.fulfillImage) {
        formData.append("fulfillImage", singleObj.fulfillImage);
      }
      if (singleObj.emptyImage) {
        formData.append("emptyImage", singleObj.emptyImage);
      }
      if (singleObj.bannerImage) {
        formData.append("bannerImage", singleObj.bannerImage);
      }
      formData.append("showProgress", singleObj.showProgress);
      formData.append("status", singleObj.status);

      const request = await axios.post(
        `${hostName}api/admin/v1/pledgeHeader`,
        formData,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        const { messageDetails } = error.response.data;

        // Check if messageDetails is an array with a single string
        if (Array.isArray(messageDetails) && messageDetails.length > 0) {
          apiError = messageDetails[0]; // Take the first string in the array
        } else {
          apiError = messageDetails;
        }
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
      };
    }
  }
);

// update pledge
export const updatePledge = createAsyncThunk(
  "pledge/updatePledge",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
          "Content-Type": "multipart/form-data", // Set the Content-Type header to 'multipart/form-data'
        },
      };

      const formData = new FormData();
      formData.append("name", singleObj.name);
      formData.append("description", singleObj.description);
      formData.append("campaignTag", singleObj.campaignTag);
      formData.append("targetCount", singleObj.targetCount);
      formData.append("unitAmount", singleObj.unitAmount);
      formData.append("targetAmount", singleObj.targetAmount);
      if (singleObj.fulfillImage) {
        formData.append("fulfillImage", singleObj.fulfillImage);
      }
      if (singleObj.emptyImage) {
        formData.append("emptyImage", singleObj.emptyImage);
      }
      if (singleObj.bannerImage) {
        formData.append("bannerImage", singleObj.bannerImage);
      }
      formData.append("showProgress", singleObj.showProgress);
      formData.append("status", singleObj.status);

      const request = await axios.post(
        `${hostName}api/admin/v1/pledgeHeader/${singleObj.tagNumber}?_method=PUT`,
        formData,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        const { messageDetails } = error.response.data;

        // Check if messageDetails is an array with a single string
        if (Array.isArray(messageDetails) && messageDetails.length > 0) {
          apiError = messageDetails[0]; // Take the first string in the array
        } else {
          apiError = messageDetails;
        }
      } else {
        apiError = error.response.data.message;
      }

      return {
        data: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// delete pledge
export const deletePledge = createAsyncThunk(
  "pledge/deletePledge",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/pledgeHeader/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;

      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }

      return {
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// read status summary (pie chart)
export const readStatusSummary = createAsyncThunk(
  "pledge/readStatusSummary",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };

    const request = await axios.get(
      `${hostName}api/admin/v1/pledgeHeaderStatusSummary?tagNumber=${singleObj.tagNumber}`,
      axiosConfig
    );
    const response = await request.data.data;
    return response;
  }
);

const pledgeSlice = createSlice({
  name: "pledge-control",
  initialState: {
    // pledges transaction records
    pledgesTransactionsLoading: false,
    pledgesTransactionsData: null,
    pledgesTransactionsError: null,
    // pledge record
    pledgeLoading: false,
    pledgeData: null,
    pledgeError: null,
    // delete image
    deleteImageLoading: false,
    deleteImageData: null,
    // add pledge
    addPledgeLoading: false,
    addPledgeData: null,
    // update pledge
    updatePledgeLoading: false,
    updatePledgeData: null,
    // delete pledge
    deletePledgeLoading: false,
    deletePledgeData: null,
    // status summary
    statusSummaryLoading: false,
    statusSummaryData: null,
    statusSummaryError: null,
  },
  reducers: {
    clearAddNewPledgeStates: (state) => {
      state.addPledgeData = null;
    },
    clearUpdatePledgeStates: (state) => {
      state.updatePledgeData = null;
    },
    clearDeletePledgeStates: (state) => {
      state.deletePledgeData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get pledges transaction records
      .addCase(getPledgesTransactionRecords.pending, (state) => {
        state.pledgesTransactionsLoading = true;
        state.pledgesTransactionsData = null;
        state.pledgesTransactionsError = null;
      })
      .addCase(getPledgesTransactionRecords.fulfilled, (state, action) => {
        state.pledgesTransactionsLoading = false;
        state.pledgesTransactionsData = action.payload;
        state.pledgesTransactionsError = null;
      })
      .addCase(getPledgesTransactionRecords.rejected, (state, action) => {
        state.pledgesTransactionsLoading = false;
        state.pledgesTransactionsData = null;
        state.pledgesTransactionsError = action.error.message;
      })
      // get pledges control records
      .addCase(getPledgesData.pending, (state) => {
        state.pledgeLoading = true;
        state.pledgeData = null;
        state.pledgeError = null;
      })
      .addCase(getPledgesData.fulfilled, (state, action) => {
        state.pledgeLoading = false;
        state.pledgeData = action.payload;
        state.pledgeError = null;
      })
      .addCase(getPledgesData.rejected, (state, action) => {
        state.pledgeLoading = false;
        state.pledgeData = null;
        state.pledgeError = action.error.message;
      })
      // filter pledge records
      .addCase(filterPledgeRecords.pending, (state) => {
        state.pledgeLoading = true;
        state.pledgeData = null;
        state.pledgeError = null;
      })
      .addCase(filterPledgeRecords.fulfilled, (state, action) => {
        state.pledgeLoading = false;
        state.pledgeData = action.payload;
        state.pledgeError = null;
      })
      .addCase(filterPledgeRecords.rejected, (state, action) => {
        state.pledgeLoading = false;
        state.pledgeData = null;
        state.pledgeError = action.error.message;
      })
      // delete image
      .addCase(deleteImage.pending, (state) => {
        state.deleteImageLoading = true;
        state.deleteImageData = null;
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        state.deleteImageLoading = false;
        state.deleteImageData = action.payload;
        const { data, tagNumber, axiosError } = action.payload;
        if (!axiosError) {
          const pledgeIndex = state.pledgeData.data.findIndex(
            (pledge) => pledge.tagNumber === tagNumber
          );
          if (pledgeIndex !== -1) {
            state.pledgeData.data[pledgeIndex] = data;
          }
        }
      })
      // add pledge
      .addCase(addNewPledge.pending, (state) => {
        state.addPledgeLoading = true;
        state.addPledgeData = null;
      })
      .addCase(addNewPledge.fulfilled, (state, action) => {
        state.addPledgeLoading = false;
        state.addPledgeData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.pledgeData.data.unshift(data);
        }
      })
      // update pledge
      .addCase(updatePledge.pending, (state) => {
        state.updatePledgeLoading = true;
        state.updatePledgeData = null;
      })
      .addCase(updatePledge.fulfilled, (state, action) => {
        state.updatePledgeLoading = false;
        state.updatePledgeData = action.payload;
        const { data, tagNumber, axiosError } = action.payload;
        if (!axiosError && data) {
          const pledgeIndex = state.pledgeData.data.findIndex(
            (pledge) => pledge.tagNumber === tagNumber
          );
          if (pledgeIndex !== -1) {
            state.pledgeData.data[pledgeIndex] = data;
          }
        }
      })
      // delete pledge
      .addCase(deletePledge.pending, (state) => {
        state.deletePledgeLoading = true;
        state.deletePledgeData = null;
      })
      .addCase(deletePledge.fulfilled, (state, action) => {
        state.deletePledgeLoading = false;
        state.deletePledgeData = action.payload;
        const { axiosError, tagNumber } = action.payload;
        if (!axiosError) {
          state.pledgeData.data = state.pledgeData.data.filter(
            (data) => data.tagNumber !== tagNumber
          );
        }
      })
      // status summary
      .addCase(readStatusSummary.pending, (state) => {
        state.statusSummaryLoading = true;
        state.statusSummaryData = null;
        state.statusSummaryError = null;
      })
      .addCase(readStatusSummary.fulfilled, (state, action) => {
        state.statusSummaryLoading = false;
        state.statusSummaryData = action.payload;
        state.statusSummaryError = null;
      })
      .addCase(readStatusSummary.rejected, (state, action) => {
        state.statusSummaryLoading = false;
        state.statusSummaryData = null;
        state.statusSummaryError = action.error.message;
      });
  },
});

export default pledgeSlice.reducer;
export const {
  clearAddNewPledgeStates,
  clearUpdatePledgeStates,
  clearDeletePledgeStates,
} = pledgeSlice.actions;
