/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../CSS/Sidebar/Campaign.scss";
import "../../CSS/Sidebar/PledgeControl.scss";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import {
  getPledgesData,
  filterPledgeRecords,
} from "../../Store/Slices/Sidebar/Pledges/PledgesSlice";
import { getNodes } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import axios from "axios";
import hostName from "../../config";
import Pledge from "../PledgeControl/Pledge";
import AddNewPledgeModal from "../PledgeControl/AddNewPledgeModal";
import EditPledgeModal from "../PledgeControl/EditPledgeModal";
import DeletePledgeModal from "../PledgeControl/DeletePledgeModal";
import StatusSummary from "../PledgeControl/StatusSummary";
import FilterPledgeModal from "../PledgeControl/FilterPledgeModal";
import PledgeDetailsDrawer from "../PledgeControl/PledgeDetailsDrawer";

const PledgeControlComponent = ({ token }) => {
  // redux state
  const { pledgeLoading, pledgeError } = useSelector((state) => state.pledge);

  // states for grid view
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // filter pledge modal
  const [filterPledgeModal, setFilterPledgeModal] = useState(false);

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get pledges data
  useEffect(() => {
    if (filteredParams) {
      dispatch(
        filterPledgeRecords({
          token,
          page: 1,
          pageSize: filteredParams.pageSize,
          order: filteredParams.order,
          apiData: filteredParams.apiData,
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    } else {
      dispatch(
        getPledgesData({
          token,
          page: 1,
          pageSize: "10",
          order: "asc",
          by: "name",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setPageSize(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  }, [filteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // all api options data states
  const [entityOptions, setEntityOptions] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  // getting all options at once
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    Promise.all([
      dispatch(getNodes(token)),
      axios.get(
        `${hostName}api/admin/v1/lov?listName=pledgeHeaderFilter`,
        axiosConfig
      ),
    ])
      .then(([campaignRes, pledgeRes]) => {
        setAllCampaigns(campaignRes.payload.campaign_tag);
        setEntityOptions(pledgeRes.data.data);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [pledgeLoading, allOptionsLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // add new pledge modal
  const [addNewPledgeModal, setAddNewPledgeModal] = useState(false);

  // edit pledge
  const [editPledgeModal, setEditPledgeModal] = useState(false);
  const [editPledgeObj, setEditPledgeObj] = useState(null);

  const handleEditPledgePopup = (obj) => {
    setEditPledgeModal(true);
    setEditPledgeObj(obj);
  };

  // delete pledge
  const [deletePledgeModal, setDeletePledgeModal] = useState(false);
  const [deletePledgeObj, setDeletePledgeObj] = useState(null);

  const handleDeletePledgePopup = (obj) => {
    setDeletePledgeModal(true);
    setDeletePledgeObj(obj);
  };

  // status summary
  const [statusSummaryModal, setStatusSummaryModal] = useState(false);
  const [statusSummaryObj, setStatusSummaryObj] = useState(null);

  const handleStatusSummaryPopup = (obj) => {
    setStatusSummaryModal(true);
    setStatusSummaryObj(obj);
  };

  // pledge details drawer
  const [pledgeDetailsDrawer, setPledgeDetailsDrawer] = useState(false);
  const [pledgeObj, setPledgeObj] = useState(null);

  const handlePledgeDetailsDrawer = (pledge) => {
    setPledgeDetailsDrawer(true);
    setPledgeObj(pledge);
  };

  // download enrollment form
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadTagNumber, setDownloadTagNumber] = useState(null);

  const handleDownloadEnrollmentForm = (obj) => {
    setDownloadLoading(true);
    setDownloadTagNumber(obj.tagNumber);

    // Create the URL string with the required parameters
    let url = `${hostName}api/admin/v1/pledgeEnrollmentForm?tagNumber=${obj.tagNumber}`;

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    // Make the API call directly in the component
    axios
      .get(url, axiosConfig)
      .then((response) => {
        // Download the file
        const file = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.setAttribute("download", `EnrollmentForm.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        // Handle unauthorized error
        if (error && error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          toast.error(`${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  // campaignTag
  const [campaignTag, setCampaignTag] = useState([]);
  const handleCampaignTagChange = (item) => {
    if (campaignTag.includes(item)) {
      setCampaignTag(campaignTag.filter((checkedItem) => checkedItem !== item));
    } else {
      setCampaignTag([...campaignTag, item]);
    }
  };

  // name
  const [name, setName] = useState("");

  // description
  const [description, setDescription] = useState("");

  // sort by
  const [sortBy, setSortBy] = useState({
    value: "name",
    label: "Name",
  });

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // handle reset filter
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilterPledgeModal(false);
    }
    setEntitySelected([]);
    setCampaignTag([]);
    setName("");
    setDescription("");
    setPageSize(perPage);
    setSortBy({
      value: "name",
      label: "Name",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  return (
    <>
      <Toaster />

      <div className="middle-area campaignComponents">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <div className="media-heading-button-container">
                <h6>Pledge Control Center</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setAddNewPledgeModal(true)}
                    >
                      New
                    </button>
                  </div>
                  <div className="filter-and-reset-btns-div">
                    <Tippy content="Filter Records">
                      <button
                        className="filter-media-modal-btn reset"
                        type="button"
                        onClick={() => setFilterPledgeModal(true)}
                      >
                        <Icon icon={filter} size={24} />
                      </button>
                    </Tippy>
                    {filteredParams && (
                      <Tippy content="Reset Records">
                        <button
                          className="filter-media-modal-btn reset"
                          type="button"
                          onClick={handleResetFilter}
                        >
                          <Icon icon={refreshCw} size={24} />
                        </button>
                      </Tippy>
                    )}
                  </div>
                </div>
              </div>

              {pledgeError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {pledgeError}
                </div>
              ) : (
                <div className="flexRow w-100">
                  <Pledge
                    token={token}
                    filteredParams={filteredParams}
                    setLoadings={setLoadings}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                    setPageSize={setPageSize}
                    downloadLoading={downloadLoading}
                    downloadTagNumber={downloadTagNumber}
                    handleEditPledgePopup={handleEditPledgePopup}
                    handleDeletePledgePopup={handleDeletePledgePopup}
                    handleStatusSummaryPopup={handleStatusSummaryPopup}
                    handlePledgeDetailsDrawer={handlePledgeDetailsDrawer}
                    handleDownloadEnrollmentForm={handleDownloadEnrollmentForm}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* add new pledge modal */}
      {addNewPledgeModal && (
        <AddNewPledgeModal
          token={token}
          setAddNewPledgeModal={setAddNewPledgeModal}
          allCampaigns={allCampaigns}
        />
      )}

      {/* edit pledge modal */}
      {editPledgeModal && (
        <EditPledgeModal
          token={token}
          setEditPledgeModal={setEditPledgeModal}
          editPledgeObj={editPledgeObj}
          allCampaigns={allCampaigns}
        />
      )}

      {/* delete pledge modal */}
      {deletePledgeModal && (
        <DeletePledgeModal
          token={token}
          setDeletePledgeModal={setDeletePledgeModal}
          deletePledgeObj={deletePledgeObj}
        />
      )}

      {/* status summary modal */}
      {statusSummaryModal && (
        <StatusSummary
          token={token}
          setStatusSummaryModal={setStatusSummaryModal}
          statusSummaryObj={statusSummaryObj}
        />
      )}

      {pledgeDetailsDrawer && (
        <PledgeDetailsDrawer
          setPledgeDetailsDrawer={setPledgeDetailsDrawer}
          pledgeObj={pledgeObj}
        />
      )}

      {/* filter modal */}
      {filterPledgeModal && (
        <FilterPledgeModal
          setFilterPledgeModal={setFilterPledgeModal}
          setFilteredParams={setFilteredParams}
          entityOptions={entityOptions}
          handleSelectChange={handleSelectChange}
          entitySelected={entitySelected}
          campaignTag={campaignTag}
          handleCampaignTagChange={handleCampaignTagChange}
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
        />
      )}
    </>
  );
};

export default PledgeControlComponent;
