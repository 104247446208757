import React from "react";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTaxReceiptCalenderConfiguration } from "../../Store/Slices/Sidebar/TaxReceiptCalender/TaxReceiptCalenderSlice";
import dayjs from "dayjs";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import Icon from "react-icons-kit";
import { basic_trashcan_remove } from "react-icons-kit/linea/basic_trashcan_remove";
import { software_pencil } from "react-icons-kit/linea/software_pencil";

// custom styles
const styles = {
  menuPortal: (base) => ({ ...base, zIndex: 10004 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const Card = ({
  token,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setLoadings,
  viewYear,
  handleEditSettingPopup,
  handleDeleteSettingPopup,
}) => {
  // redux state
  const { taxReceiptCalenderConfigurationData } = useSelector(
    (state) => state.taxReceiptCalender
  );

  // get title class name
  const getTitleClass = (lastDate) => {
    if (!lastDate) return "card-title green";
    return dayjs(lastDate).isBefore(dayjs())
      ? "card-title yellow"
      : "card-title green";
  };

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);

      dispatch(
        getTaxReceiptCalenderConfiguration({
          token,
          year: viewYear,
          page: 1,
          pageSize: value,
          by: "year",
          order: "asc",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);

      dispatch(
        getTaxReceiptCalenderConfiguration({
          token,
          year: viewYear,
          page: value,
          pageSize: perPage,
          by: "year",
          order: "asc",
        })
      ).then((res) => {
        if (
          res.error &&
          res.error.message === "Request failed with status code 401"
        ) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setCurrentPage(res.payload.links.currentPage);
          setPerPage(res.payload.links.perPage);
          setTotalPages(res.payload.links.lastPage);
        }
      });
    }
  };

  return (
    <>
      {taxReceiptCalenderConfigurationData?.data?.length > 0 ? (
        <>
          <div className="card-container">
            {taxReceiptCalenderConfigurationData.data.map((item, index) => (
              <div key={index} className="card">
                {/* Title Bar */}
                <div className={getTitleClass(item.lastDate)}>{item.year}</div>
                {/* Card Content */}
                <div className="card-content">
                  <div className="data-card-item">
                    <div className="label">Start Date</div>
                    <div className="value">{item.startDate || "N/A"}</div>
                  </div>
                  <div className="data-card-item">
                    <div className="label">End Date</div>
                    <div className="value">{item.endDate || "N/A"}</div>
                  </div>
                  <div className="data-card-item">
                    <div className="label">Tax Filing Date</div>
                    <div className="value">{item.lastDate || "N/A"}</div>
                  </div>
                  <div className="data-card-item">
                    <div className="label">Last Modified By</div>
                    <div className="value">{item.lastModifiedBy || "N/A"}</div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: "16px",
                      borderTop: "1px solid #ddd",
                      marginTop: "16px",
                    }}
                  >
                    <Tippy content="Edit Setting">
                      <span
                        style={{
                          marginRight: "12px",
                          color: "#007c16",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEditSettingPopup(item)}
                      >
                        <Icon icon={software_pencil} size={24} />
                      </span>
                    </Tippy>
                    <Tippy content="Delete Setting">
                      <span
                        style={{ color: "#db364b", cursor: "pointer" }}
                        onClick={() => handleDeleteSettingPopup(item)}
                      >
                        <Icon icon={basic_trashcan_remove} size={24} />
                      </span>
                    </Tippy>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="per-page-and-pagination">
            <div className="per-page">
              <label>Records per page</label>
              <Select
                value={perPageOptions.find(
                  (option) => option.value === String(perPage)
                )}
                onChange={(option) => handlePerPage(option.value)}
                options={perPageOptions}
                styles={styles}
                menuPlacement="top"
                menuPortalTarget={document.body}
              />
            </div>
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Settings Found">
            <img src={noDataFound} alt="No Settings Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default Card;
