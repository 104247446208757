import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComponent = ({ data }) => {
  // Create arrays for labels and values from your data
  const labels = data.map((item) => item.label);
  const counts = data.map((item) => item.count);

  // Optionally, define a color palette. The number of colors can be adjusted as needed.
  const backgroundColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
  ];

  // If your data array length is dynamic, we can dynamically assign colors.
  // Here we use the provided colors cyclically.
  const dynamicColors = labels.map(
    (_, index) => backgroundColors[index % backgroundColors.length]
  );

  const chartData = {
    labels,
    datasets: [
      {
        data: counts,
        backgroundColor: dynamicColors,
        hoverBackgroundColor: dynamicColors,
      },
    ],
  };

  return (
    <Pie
      data={chartData}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: "left",
          },
          tooltip: {
            backgroundColor: "#fff",
            titleColor: "#000",
            bodyColor: "#000",
          },
          datalabels: {
            display: false,
          },
        },
      }}
    />
  );
};

export default PieChartComponent;
