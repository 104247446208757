// hooks
import { useState, useEffect } from "react";
// react-router-dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// components
import QuickRedirect from "./Pages/QuickRedirect";
import Login from "./Pages/Auth/Login";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Analytics from "./Pages/Sidebar/Analytics";
import Campaigns from "./Pages/Sidebar/Campaigns";
import Dashboard from "./Pages/Sidebar/Dashboard";
import Donors from "./Pages/Sidebar/Donors";
import DonorDetails from "./Pages/Donors/DonorDetails";
import ECheck from "./Pages/Sidebar/ECheck";
import RecurringPayments from "./Pages/Sidebar/RecurringPayments";
import RecordTransactions from "./Pages/Sidebar/RecordTransactions";
import Pledges from "./Pages/Sidebar/Pledges";
import Channels from "./Pages/Sidebar/Channels";
import DataManage from "./Pages/Sidebar/DataManage";
import Organization from "./Pages/Sidebar/Organization";
import TaxReceiptCalender from "./Pages/Sidebar/TaxReceiptCalender";
import TaxReceipts from "./Pages/Sidebar/TaxReceipts";
import AppExplorer from "./Pages/Sidebar/AppExplorer";
import ETransfer from "./Pages/Sidebar/ETransfer";
import PledgeControl from "./Pages/Sidebar/PledgeControl";
import MediaCenter from "./Pages/Sidebar/MediaCenter";
import Reports from "./Pages/Sidebar/Reports";
import Users from "./Pages/Sidebar/Users";
import Notifications from "./Pages/Sidebar/Notifications";
// pagination css
import "react-responsive-pagination/themes/classic.css";
// react datepicker css
import "react-datepicker/dist/react-datepicker.css";
// react date-picker css
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Tippy css
import "tippy.js/dist/tippy.css";
// sweet alert css
import "sweetalert2/dist/sweetalert2.min.css";
// mdb css
import "mdb-react-ui-kit/dist/css/mdb.min.css";
// react-lazy-load-image-component css
import "react-lazy-load-image-component/src/effects/blur.css";
// react-quill css
import "react-quill/dist/quill.snow.css";

// cache-buster
import CacheBuster from "react-cache-buster";
import packageFile from "../package.json";
import CacheLoading from "./CacheLoading";

function App() {
  // isProduction
  const isProduction = process.env.NODE_ENV === "production";

  // sidebar toggled
  const [sidebarToggled, setSidebarToggled] = useState(false);

  // quick links dropdown
  const [quickLinksDropdown, setQuickLinksDropdown] = useState(false);

  // toggle sidebar when screen reaches 1024px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setSidebarToggled(true);
      } else {
        setSidebarToggled(false);
      }
    };
    // initial call
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // destructure version
  const { version } = packageFile;

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<CacheLoading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="wrapper">
        <Router>
          <Routes>
            {/* Quick redirect */}
            <Route exact path="/" element={<QuickRedirect />} />
            {/* Auth */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            {/* Analytics */}
            <Route
              path="/analytics"
              element={
                <Analytics
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Campaigns */}
            <Route
              path="/campaigns"
              element={
                <Campaigns
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Dashboard */}
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Donors */}
            <Route
              path="/donors"
              element={
                <Donors
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Donor details */}
            <Route
              path="/donors/:name"
              element={
                <DonorDetails
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* eCheck */}
            <Route
              path="/fundraising/echeck"
              element={
                <ECheck
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Recurring Payments */}
            <Route
              path="/fundraising/recurring-payments"
              element={
                <RecurringPayments
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Transactions */}
            <Route
              path="/fundraising/transactions"
              element={
                <RecordTransactions
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Pledges */}
            {/* <Route
              path="/fundraising/pledges"
              element={
                <Pledges
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            /> */}
            {/* Channels */}
            <Route
              path="/manage/channels"
              element={
                <Channels
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Data */}
            <Route
              path="/manage/data"
              element={
                <DataManage
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Organization */}
            <Route
              path="/manage/organization"
              element={
                <Organization
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* taxreceipt-calender */}
            <Route
              path="/manage/taxreceipt-calender"
              element={
                <TaxReceiptCalender
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* tax receipts */}
            <Route
              path="/manage/taxreceipts"
              element={
                <TaxReceipts
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* etransfer */}
            <Route
              path="/manage/etransfer"
              element={
                <ETransfer
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* pledge-control */}
            <Route
              path="/manage/pledge-control"
              element={
                <PledgeControl
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* app explorer */}
            <Route
              path="/integration-hub/app-explorer"
              element={
                <AppExplorer
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Media Center */}
            <Route
              path="/media-center"
              element={
                <MediaCenter
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Reports */}
            <Route
              path="/reports"
              element={
                <Reports
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Users */}
            <Route
              path="/users"
              element={
                <Users
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
            {/* Notifications */}
            <Route
              path="/notifications"
              element={
                <Notifications
                  sidebarToggled={sidebarToggled}
                  setSidebarToggled={setSidebarToggled}
                  quickLinksDropdown={quickLinksDropdown}
                  setQuickLinksDropdown={setQuickLinksDropdown}
                />
              }
            />
          </Routes>
        </Router>
      </div>
    </CacheBuster>
  );
}

export default App;
