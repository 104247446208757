/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { basic_picture } from "react-icons-kit/linea/basic_picture";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearAddNewPledgeStates,
  addNewPledge,
} from "../../Store/Slices/Sidebar/Pledges/PledgesSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// custom text field
const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
  & .Mui-disabled {
    background-color: #f5f5f5;
  }
`;

// custom styles
const styles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 100001, // Set the z-index high enough to appear above all elements
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const AddNewPledgeModal = ({ token, setAddNewPledgeModal, allCampaigns }) => {
  // redux state
  const { addPledgeLoading, addPledgeData } = useSelector(
    (state) => state.pledge
  );

  // close modal
  const handleCloseModal = () => {
    if (!addPledgeLoading) {
      setAddNewPledgeModal(false);
    }
  };

  // form states
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState({
    value: "",
    label: "",
    minimumAmount: "",
  });
  // target count
  const [targetCount, setTargetCount] = useState("");

  // target count change
  const handleTargetCountChange = (event) => {
    let newValue = parseInt(event.target.value, 10);

    if (newValue < 0) {
      newValue = 0;
    }
    setTargetCount(newValue);
  };

  // unit amount
  const [unitAmount, setUnitAmount] = useState("");

  // unit amount change
  const handleUnitAmountChange = (event) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/[^0-9.]/g, "");
    const parts = newValue.split(".");
    if (parts.length > 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setUnitAmount(newValue);
  };

  // target amount
  const [targetAmount, setTargetAmount] = useState(0);

  // targetAmount = targetCount * unitAmount
  useEffect(() => {
    const count = parseInt(targetCount, 10) || 0; // Default to 0 if falsy
    const amount = parseFloat(unitAmount) || 0; // Default to 0 if falsy
    setTargetAmount(count * amount);
  }, [targetCount, unitAmount]);

  // pledged image
  const [fulfillImageFile, setFulfillImageFile] = useState(null);
  const [fulfillImageURL, setFulfillImageURL] = useState(null);
  const [fulfillFileError, setFulfillFileError] = useState(null);

  const handleFulfillImage = (file) => {
    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = file.type;

      // Maximum file size in KB
      const maxFileSizeKB = 2048;

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setFulfillImageFile(null);
        setFulfillImageURL(null);
        setFulfillFileError("Invalid file type. Only PNG, JPEG, and JPG.");
        return;
      }

      // Check file size
      if (file.size > maxFileSizeKB * 1024) {
        setFulfillImageFile(null);
        setFulfillImageURL(null);
        setFulfillFileError(`File size should not exceed ${maxFileSizeKB}KB.`);
        return;
      }

      // File is valid
      setFulfillImageFile(file);
      setFulfillFileError(null);

      // Create object URL and save it in the state
      const url = URL.createObjectURL(file);
      setFulfillImageURL(url);
    } else {
      setFulfillFileError(null);
      setFulfillImageFile(null);
      setFulfillImageURL(null);
    }
  };

  // non-pledged image
  const [emptyImageFile, setEmptyImageFile] = useState(null);
  const [emptyImageURL, setEmptyImageURL] = useState(null);
  const [emptyFileError, setEmptyFileError] = useState(null);

  const handleEmptyImage = (file) => {
    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = file.type;

      // Maximum file size in KB
      const maxFileSizeKB = 2048;

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setEmptyImageFile(null);
        setEmptyImageURL(null);
        setEmptyFileError("Invalid file type. Only PNG, JPEG, and JPG.");
        return;
      }

      // Check file size
      if (file.size > maxFileSizeKB * 1024) {
        setEmptyImageFile(null);
        setEmptyImageURL(null);
        setEmptyFileError(`File size should not exceed ${maxFileSizeKB}KB.`);
        return;
      }

      // File is valid
      setEmptyImageFile(file);
      setEmptyFileError(null);

      // Create object URL and save it in the state
      const url = URL.createObjectURL(file);
      setEmptyImageURL(url);
    } else {
      setEmptyFileError(null);
      setEmptyImageFile(null);
      setEmptyImageURL(null);
    }
  };

  // bannner image
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImageURL, setBannerImageURL] = useState(null);
  const [bannerFileError, setBannerFileError] = useState(null);

  const handleBannerImage = (file) => {
    if (file) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = file.type;

      // Maximum file size in KB
      const maxFileSizeKB = 2048;

      // Check file type
      if (!allowedFileTypes.includes(fileType)) {
        setBannerImageFile(null);
        setBannerImageURL(null);
        setBannerFileError("Invalid file type. Only PNG, JPEG, and JPG.");
        return;
      }

      // Check file size
      if (file.size > maxFileSizeKB * 1024) {
        setBannerImageFile(null);
        setBannerImageURL(null);
        setBannerFileError(`File size should not exceed ${maxFileSizeKB}KB.`);
        return;
      }

      // File is valid
      setBannerImageFile(file);
      setBannerFileError(null);

      // Create object URL and save it in the state
      const url = URL.createObjectURL(file);
      setBannerImageURL(url);
    } else {
      setBannerFileError(null);
      setBannerImageFile(null);
      setBannerImageURL(null);
    }
  };

  // show progress
  const [showProgress, setShowProgress] = useState(true);

  const handleShowProgressChange = () => {
    setShowProgress((prevStatus) => !prevStatus);
  };

  // status
  const [status, setStatus] = useState("true");

  const handleStatusChange = () => {
    setStatus((prevStatus) => (prevStatus === "true" ? "false" : "true"));
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear prev states
  useEffect(() => {
    dispatch(clearAddNewPledgeStates());
  }, []);

  // add new pledge event
  const handleAddNewPledge = (e) => {
    e.preventDefault();

    const singleObj = {
      token,
      name,
      description,
      campaignTag: selectedCampaign.value,
      targetCount,
      unitAmount,
      targetAmount,
      fulfillImage: fulfillImageFile,
      emptyImage: emptyImageFile,
      bannerImage: bannerImageFile,
      showProgress,
      status,
    };
    dispatch(addNewPledge(singleObj)).then((res) => {
      if (res.payload.successMsg) {
        setAddNewPledgeModal(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>New Pledge</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form className="update-media-form" onSubmit={handleAddNewPledge}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  {/* name */}
                  <div className="form-group mb-3">
                    <label htmlFor="name">Name</label>
                    <CustomTextField
                      type="text"
                      className="edit-media-inputs"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      title=""
                      required
                      placeholder="Name"
                    />
                  </div>

                  {/* campaign */}
                  <div className="form-group mb-3 position-relative">
                    <label className="form-label me-2">Campaign</label>
                    <Select
                      styles={styles}
                      required
                      menuPlacement="bottom"
                      value={allCampaigns.find(
                        (option) => option.value === selectedCampaign.value
                      )}
                      onChange={(option) => setSelectedCampaign(option)}
                      options={allCampaigns}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                    />
                  </div>
                </MDBCol>

                {/* description */}
                <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="edit-media-inputs text-area"
                    style={{ minHeight: "124px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    placeholder="Description"
                  ></textarea>
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-4">
                {/* target count */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label htmlFor="target count">Target Count</label>
                  <CustomTextField
                    type="number"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={targetCount}
                    onChange={handleTargetCountChange}
                    inputMode="numeric"
                    title=""
                    required
                    placeholder="Target Count"
                  />
                </MDBCol>

                {/* unit amount */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label htmlFor="target amount">Unit Amount</label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={unitAmount}
                    onChange={handleUnitAmountChange}
                    inputMode="decimal"
                    title=""
                    required
                    placeholder="Unit Amount"
                  />
                </MDBCol>

                {/* target amount */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label htmlFor="target amount">Target Amount</label>
                  <CustomTextField
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    value={targetAmount}
                    disabled
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                {/* pledged image */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label htmlFor="Pledged Image">
                    Pledged Image
                    {fulfillImageURL && (
                      <Tippy
                        content={
                          <img
                            src={fulfillImageURL}
                            alt="Fulfill"
                            style={{ width: "400px", height: "auto" }}
                          />
                        }
                        zIndex={100001}
                        maxWidth={100 + "%"}
                        // placement="top-end"
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "#007c16",
                          }}
                        >
                          <Icon icon={basic_picture} size={24} />
                        </span>
                      </Tippy>
                    )}
                  </label>
                  <input
                    className="edit-media-inputs"
                    type="file"
                    onChange={(e) => handleFulfillImage(e.target.files[0])}
                    accept=".png, .jpeg, .jpg"
                    // required
                    style={{ color: fulfillFileError && "red" }}
                  />
                </MDBCol>

                {/* non-pledged image */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label htmlFor="Non-Pledged Image">
                    Non-Pledged Image
                    {emptyImageURL && (
                      <Tippy
                        content={
                          <img
                            src={emptyImageURL}
                            alt="Empty"
                            style={{ width: "400px", height: "auto" }}
                          />
                        }
                        zIndex={100001}
                        maxWidth={100 + "%"}
                        placement="top-end"
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "#007c16",
                          }}
                        >
                          <Icon icon={basic_picture} size={24} />
                        </span>
                      </Tippy>
                    )}
                  </label>
                  <input
                    className="edit-media-inputs"
                    type="file"
                    onChange={(e) => handleEmptyImage(e.target.files[0])}
                    accept=".png, .jpeg, .jpg"
                    // required
                    style={{ color: emptyFileError && "red" }}
                  />
                </MDBCol>

                {/* Banner image */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <label htmlFor="Bannner Image">
                    Banner Image
                    {bannerImageURL && (
                      <Tippy
                        content={
                          <img
                            src={bannerImageURL}
                            alt="Banner"
                            style={{ width: "400px", height: "auto" }}
                          />
                        }
                        zIndex={100001}
                        maxWidth={100 + "%"}
                        placement="top-end"
                      >
                        <span
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "#007c16",
                          }}
                        >
                          <Icon icon={basic_picture} size={24} />
                        </span>
                      </Tippy>
                    )}
                  </label>
                  <input
                    className="edit-media-inputs"
                    type="file"
                    onChange={(e) => handleBannerImage(e.target.files[0])}
                    accept=".png, .jpeg, .jpg"
                    // required
                    style={{ color: bannerFileError && "red" }}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3" style={{ padding: "0 8px" }}>
                <div
                  className={
                    fulfillFileError || emptyFileError || bannerFileError
                      ? "error-msg"
                      : "info-msg"
                  }
                >
                  Only PNG and JPG files are allowed for the above uploads and
                  max file size is 2MB.
                </div>
              </MDBRow>

              <MDBRow className="mb-3">
                {/* show progress */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <h6>Show Progress</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={showProgress}
                      onChange={handleShowProgressChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>

                {/* status */}
                <MDBCol className="col-12 col-md-4 mb-2 mb-md-0">
                  <h6>Status</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={status === "true"}
                      onChange={handleStatusChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </MDBCol>
              </MDBRow>

              {addPledgeData &&
                (addPledgeData.apiError || addPledgeData.axiosError) && (
                  <div className="error-msg">
                    {addPledgeData.apiError ? (
                      <>
                        {typeof addPledgeData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(addPledgeData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {addPledgeData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{addPledgeData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{addPledgeData.axiosError}</>
                    )}
                  </div>
                )}

              {/* cancel and next button */}
              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={addPledgeLoading}
                  style={{
                    opacity:
                      fulfillFileError || emptyFileError || bannerFileError
                        ? 0.5
                        : 1,
                    pointerEvents:
                      fulfillFileError || emptyFileError || bannerFileError
                        ? "none"
                        : "auto",
                  }}
                >
                  {addPledgeLoading ? (
                    <MetroSpinner
                      loading={addPledgeLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "ADD"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddNewPledgeModal;
