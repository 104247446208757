import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  filterPledgeRecords,
  getPledgesData,
} from "../../Store/Slices/Sidebar/Pledges/PledgesSlice";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { copy } from "react-icons-kit/feather/copy";
import { basic_picture } from "react-icons-kit/linea/basic_picture";
import toast from "react-hot-toast";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import hostName from "../../config";
import PledgeImagesModal from "./PledgeImagesModal";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const Pledge = ({
  token,
  filteredParams,
  setLoadings,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setPageSize,
  downloadLoading,
  downloadTagNumber,
  handleEditPledgePopup,
  handleDeletePledgePopup,
  handleStatusSummaryPopup,
  handlePledgeDetailsDrawer,
  handleDownloadEnrollmentForm,
}) => {
  const { pledgeData } = useSelector((state) => state.pledge);

  // per page options
  const perPageOptions = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);

      if (filteredParams) {
        dispatch(
          filterPledgeRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getPledgesData({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            by: "name",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);

      if (filteredParams) {
        dispatch(
          filterPledgeRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getPledgesData({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            by: "name",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // copy
  const handleCopy = (item) => {
    navigator.clipboard.writeText(item.qrCode).then(() => {
      toast.success(`Text copied to the clipboard`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  // handle images
  const [imgModal, setImgModal] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgName, setImgName] = useState(null);
  const [tagNumber, setTagNumber] = useState(null);

  const handleImage = (url) => {
    if (url) {
      setImgModal(true);
      setImgUrl(url);
    }
  };

  // generate qr code

  // states
  // const [qrCodeLoading, setQrCodeLoading] = useState(false);
  // const [campaignTagNumber, setCampaignTagNumber] = useState(null);
  // const [qrCodeError, setQrCodeError] = useState(null);

  // event
  const handleGenerateQrCode = (item) => {
    // setQrCodeLoading(true);
    // setCampaignTagNumber(item.tagNumber);
    // setQrCodeError(false);

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .get(`${hostName}api/admin/v1/qrCode?data=${item.qrCode}`, axiosConfig)
      .then((response) => {
        // download png
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.name}_QR_Code.png`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          toast.error(error.message, {
            position: "top-center",
            duration: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .finally(() => {
        // setQrCodeLoading(false);
      });
  };

  return (
    <>
      {imgModal && imgUrl && (
        <PledgeImagesModal
          token={token}
          setImgModal={setImgModal}
          imgUrl={imgUrl}
          setImgUrl={setImgUrl}
          imgName={imgName}
          setImgName={setImgName}
          tagNumber={tagNumber}
          setTagNumber={setTagNumber}
        />
      )}

      {pledgeData?.data?.length > 0 ? (
        <>
          {pledgeData.data.map((pledge) => (
            <div key={pledge.tagNumber} className="mb-3 campaignCard">
              <div
                className={`h-100 ${
                  pledge.status ? "borderTopGreen py-1" : "borderTopYellow"
                }`}
              >
                <div className="flexBetweenCenter mb-3 gap-20">
                  <h1
                    className="campaignTitle"
                    onClick={() => handlePledgeDetailsDrawer(pledge)}
                  >
                    {pledge.name}
                  </h1>

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {pledge.qrCode && (
                      <Tippy
                        interactive
                        content={
                          <a
                            href={pledge.qrCode}
                            target="blank"
                            rel="noreferrer noopener"
                            className="qrlink-anchor"
                          >
                            {pledge.qrCode}
                          </a>
                        }
                        maxWidth={`none`}
                      >
                        <p
                          className="mb-0"
                          style={{
                            marginRight: "5px",
                            padding: "5px 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCopy(pledge)}
                        >
                          <Icon icon={copy} size={28} />
                        </p>
                      </Tippy>
                    )}

                    <p
                      className={`mb-0 status ${
                        pledge.status
                          ? "bgGreen text-white"
                          : "bgYellow text-dark"
                      }`}
                      style={{ padding: "5px 20px" }}
                    >
                      {pledge.statusText}
                    </p>
                  </div>
                </div>

                <div className="campaignTable">
                  <MDBTable align="middle">
                    <MDBTableHead className="media-thead">
                      <tr>
                        <th scope="col">Campaign</th>
                        <th scope="col" style={{ textAlign: "right" }}>
                          Show Progress
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody className="media-body">
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <LazyLoadImage
                                src={pledge.campaignImage}
                                alt={pledge.campaign}
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                                style={{
                                  objectFit: "cover",
                                  overflowClipMargin: "unset",
                                }}
                              />
                            </div>
                            <div className="ms-3">
                              <span style={{ color: "#757575" }}>
                                <p className="fw-bold mb-1">
                                  {pledge.campaign}
                                </p>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                              marginRight: "28px",
                            }}
                          >
                            {pledge.showProgress ? "YES" : "NO"}
                          </p>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>

                <div className="campaignTable">
                  <MDBTable align="middle">
                    <MDBTableHead className="media-thead">
                      <tr>
                        <th scope="col">Target Count</th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Unit Amount
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          Target Amount
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody className="media-body">
                      <tr>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px", marginLeft: "28px" }}
                          >
                            {pledge.targetCount}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px", textAlign: "center" }}
                          >
                            {pledge.currencySymbol}

                            {pledge.unitAmount}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                              marginRight: "14px",
                            }}
                          >
                            {pledge.currencySymbol}

                            {pledge.targetAmount}
                          </p>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>

                <div className="campaignTable">
                  <MDBTable align="middle">
                    <MDBTableHead className="media-thead">
                      <tr>
                        <th scope="col">Pledged Image</th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Non-Pledged Image
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          Banner Image
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody className="media-body">
                      <tr>
                        <td>
                          {pledge.fulfillImage ? (
                            <Icon
                              icon={basic_picture}
                              style={{
                                color: "#007c16",
                                cursor: "pointer",
                                marginLeft: "28px",
                              }}
                              size={32}
                              onClick={() => {
                                handleImage(pledge.fulfillImage);
                                setImgName("Pledged Image");
                                setTagNumber(pledge.tagNumber);
                              }}
                            />
                          ) : (
                            <p
                              className="fw-normal mb-1"
                              style={{
                                fontSize: "16px",
                                marginLeft: "28px",
                              }}
                            >
                              N/A
                            </p>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {pledge.emptyImage ? (
                            <Icon
                              icon={basic_picture}
                              style={{ color: "#007c16", cursor: "pointer" }}
                              size={32}
                              onClick={() => {
                                handleImage(pledge.emptyImage);
                                setImgName("Non-Pledged Image");
                                setTagNumber(pledge.tagNumber);
                              }}
                            />
                          ) : (
                            <p
                              className="fw-normal mb-1"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              N/A
                            </p>
                          )}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {pledge.bannerImage ? (
                            <Icon
                              icon={basic_picture}
                              style={{
                                color: "#007c16",
                                cursor: "pointer",
                                marginRight: "28px",
                              }}
                              size={32}
                              onClick={() => {
                                handleImage(pledge.bannerImage);
                                setImgName("Banner Image");
                                setTagNumber(pledge.tagNumber);
                              }}
                            />
                          ) : (
                            <p
                              className="fw-normal mb-1"
                              style={{
                                fontSize: "16px",
                                marginRight: "28px",
                              }}
                            >
                              N/A
                            </p>
                          )}
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>

                <div className="campaignTable">
                  <MDBTable align="middle">
                    <MDBTableHead className="media-thead">
                      <tr>
                        <th scope="col">Pledge Count</th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Pledged Amount
                        </th>
                        <th
                          scope="col"
                          style={{
                            textAlign: "right",
                          }}
                        >
                          Fulfillment ( % )
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody className="media-body">
                      <tr>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px", marginLeft: "28px" }}
                          >
                            {pledge.pledgeCount}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{ fontSize: "16px", textAlign: "center" }}
                          >
                            {pledge.currencySymbol}

                            {pledge.pledgedAmount}
                          </p>
                        </td>
                        <td>
                          <p
                            className="fw-normal mb-1"
                            style={{
                              fontSize: "16px",
                              textAlign: "right",
                              marginRight: "14px",
                            }}
                          >
                            {pledge.fulfillmentPercentage.toFixed(2)} %
                          </p>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>

                <div className="mb-3 progressContent">
                  <span className="progress rounded" role="progressbar">
                    <span
                      className="progress-bar bgGreen"
                      style={{
                        width: `${pledge.fulfillmentPercentage}%`,
                      }}
                    ></span>
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  {downloadLoading &&
                    downloadTagNumber === pledge.tagNumber && (
                      <span style={{ marginRight: "7px" }}>
                        Downloading Enrollment Form... Please wait.
                      </span>
                    )}
                  <MDBDropdown dropup group>
                    <MDBDropdownToggle
                      color="success"
                      style={{ background: "#007c16" }}
                      className="pledge-dropdown-toggle"
                    >
                      Actions
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditPledgePopup(pledge);
                        }}
                      >
                        Edit
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeletePledgePopup(pledge);
                        }}
                      >
                        Delete
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        style={{
                          opacity: !pledge.qrCode ? 0.5 : 1,
                          pointerEvents: !pledge.qrCode ? "none" : "auto",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleGenerateQrCode(pledge);
                        }}
                      >
                        Generate QR Code
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleStatusSummaryPopup(pledge);
                        }}
                      >
                        Status Summary
                      </MDBDropdownItem>
                      <MDBDropdownItem
                        link
                        onClick={(e) => {
                          e.preventDefault();
                          handleDownloadEnrollmentForm(pledge);
                        }}
                      >
                        Download Enrollment Form
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
            </div>
          ))}

          <div className="per-page-and-pagination">
            <div className="per-page mb-3 mb-md-0">
              <label className="me-2">Records per page</label>
              <Select
                options={perPageOptions}
                value={perPageOptions.find(
                  (state) => state.value === String(perPage)
                )}
                onChange={(option) => handlePerPage(option.value)}
                styles={styles}
                menuPlacement="top"
              />
            </div>
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Pledge Found">
            <img src={noDataFound} alt="No Pledge Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default Pledge;
