/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearEditSettingStates,
  updateSetting,
} from "../../Store/Slices/Sidebar/TaxReceiptCalender/TaxReceiptCalenderSlice";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import ReactDatePicker from "react-datepicker";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const EditSettingPopup = ({ token, setEditSettingPopup, editSettingObj }) => {
  // redux state
  const { updateSettingLoading, updateSettingData } = useSelector(
    (state) => state.taxReceiptCalender
  );

  // close modal
  const handleCloseModal = () => {
    if (!updateSettingLoading) {
      setEditSettingPopup(false);
    }
  };

  // form states
  const [year, setYear] = useState(editSettingObj.year || "");

  // start date
  const [startDate, setStartDate] = useState(
    editSettingObj.startDate
      ? new Date(editSettingObj.startDate + "T00:00:00")
      : new Date()
  );

  // end date
  const [endDate, setEndDate] = useState(
    editSettingObj.endDate
      ? new Date(editSettingObj.endDate + "T00:00:00")
      : new Date()
  );

  // tax filing date
  const [taxDate, setTaxDate] = useState(
    editSettingObj.lastDate
      ? new Date(editSettingObj.lastDate + "T00:00:00")
      : new Date()
  );

  // format date
  const formatDate = (dateObj) => {
    if (dateObj) {
      const year = dateObj.getFullYear().toString();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear previous error msg
  useEffect(() => {
    dispatch(clearEditSettingStates());
  }, []);

  // submit event
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateSetting({
        token,
        tagNumber: editSettingObj.tagNumber,
        year: Number(year),
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        lastDate: formatDate(taxDate),
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setEditSettingPopup(false);

        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className={`update-widgets-modal`}
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Edit Setting</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form className="update-media-form" onSubmit={handleSubmit}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="year">Year</label>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    placeholder="Year"
                    value={year}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Allow only digits and limit to 4 characters
                      if (/^\d{0,4}$/.test(value)) {
                        setYear(value);
                      }
                    }}
                    required
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="startDate">Start Date</label>
                  <ReactDatePicker
                    style={{
                      width: 100 + "%",
                    }}
                    className="edit-media-inputs"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    placeholderText="Start Date"
                    required
                    portalId="start-date-datepicker"
                    popperClassName="custom-datepicker-zindex"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="endDate">End Date</label>
                  <ReactDatePicker
                    style={{
                      width: 100 + "%",
                    }}
                    className="edit-media-inputs"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    placeholderText="End Date"
                    required
                    portalId="end-date-datepicker"
                    popperClassName="custom-datepicker-zindex"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label htmlFor="taxFilingDate">Tax Filing Date</label>
                  <ReactDatePicker
                    style={{
                      width: 100 + "%",
                    }}
                    className="edit-media-inputs"
                    selected={taxDate}
                    onChange={(date) => setTaxDate(date)}
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    placeholderText="Tax Filing Date"
                    portalId="tax-date-datepicker"
                    popperClassName="custom-datepicker-zindex"
                  />
                </MDBCol>
              </MDBRow>

              {updateSettingData &&
                updateSettingData.tagNumber === editSettingObj.tagNumber &&
                (updateSettingData.apiError ||
                  updateSettingData.axiosError) && (
                  <div className="error-msg">
                    {updateSettingData.apiError ? (
                      <>
                        {typeof updateSettingData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(updateSettingData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {updateSettingData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{updateSettingData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{updateSettingData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={updateSettingLoading}
                >
                  {updateSettingLoading ? (
                    <MetroSpinner
                      loading={updateSettingLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "UPDATE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditSettingPopup;
