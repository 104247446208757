/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/TaxReceiptCalender.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTaxReceiptCalenderConfiguration } from "../../Store/Slices/Sidebar/TaxReceiptCalender/TaxReceiptCalenderSlice";
import toast, { Toaster } from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
// import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { search } from "react-icons-kit/feather/search";
// import { refreshCw } from "react-icons-kit/feather/refreshCw";
import Card from "../TaxReceiptCalender/Card";
import NewSettingPopup from "../TaxReceiptCalender/NewSettingPopup";
import DeleteSettingPopup from "../TaxReceiptCalender/DeleteSettingPopup";
import EditSettingPopup from "../TaxReceiptCalender/EditSettingPopup";

const TaxReceiptCalenderComponent = ({ token }) => {
  // redux state
  const {
    taxReceiptCalenderConfigurationLoading,
    taxReceiptCalenderConfigurationError,
  } = useSelector((state) => state.taxReceiptCalender);

  // states
  const [viewYear, setViewYear] = useState("");
  const [inputYear, setInputYear] = useState("");

  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // tax receipt calender configuration
  useEffect(() => {
    dispatch(
      getTaxReceiptCalenderConfiguration({
        token,
        year: viewYear,
        page: 1,
        pageSize: "10",
        order: "asc",
        by: "year",
      })
    ).then((res) => {
      if (
        res.error &&
        res.error.message === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        setCurrentPage(res.payload.links.currentPage);
        setPerPage(res.payload.links.perPage);
        setTotalPages(res.payload.links.lastPage);
      }
    });
  }, [viewYear]);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [taxReceiptCalenderConfigurationLoading];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // change view year to get fresh data by triggering useEffect
  const handleFetchFreshData = (e) => {
    e.preventDefault();
    // Check if inputYear is a valid 4-digit integer
    if (/^\d{4}$/.test(inputYear) || !inputYear) {
      if (inputYear !== viewYear) {
        setLoadings(true);
        setViewYear(inputYear);
      }
    } else {
      toast.error("Please enter a valid 4-digit year.", {
        position: "top-center",
        duration: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // reset year
  // const handleResetFilter = () => {
  //   setLoadings(true);
  //   setViewYear("");
  //   setInputYear("");

  //   dispatch(
  //     getTaxReceiptCalenderConfiguration({
  //       token,
  //       year: "",
  //       page: 1,
  //       pageSize: "10",
  //       order: "asc",
  //       by: "year",
  //     })
  //   ).then((res) => {
  //     if (
  //       res.error &&
  //       res.error.message === "Request failed with status code 401"
  //     ) {
  //       localStorage.removeItem("user");
  //       navigate("/login");
  //     } else {
  //       setCurrentPage(res.payload.links.currentPage);
  //       setPerPage(res.payload.links.perPage);
  //       setTotalPages(res.payload.links.lastPage);
  //     }
  //   });
  // };

  // new setting popup
  const [newSettingPopup, setNewSettingPopup] = useState(false);

  // edit setting
  const [editSettingPopup, setEditSettingPopup] = useState(false);
  const [editSettingObj, setEditSettingObj] = useState(null);

  const handleEditSettingPopup = (obj) => {
    setEditSettingPopup(true);
    setEditSettingObj(obj);
  };

  // delete setting
  const [deleteSettingPopup, setDeleteSettingPopup] = useState(false);
  const [deleteSettingObj, setDeleteSettingObj] = useState(null);

  const handleDeleteSettingPopup = (obj) => {
    setDeleteSettingPopup(true);
    setDeleteSettingObj(obj);
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <div
                className="media-heading-button-container"
                style={{ padding: "0 20px" }}
              >
                <h6>
                  Settings
                  {viewYear && (
                    <span style={{ color: "#007c16" }}> - {viewYear}</span>
                  )}
                </h6>

                <div className="add-and-filter-btns-div">
                  <form
                    onSubmit={handleFetchFreshData}
                    className="search-year-form"
                  >
                    <input
                      type="text"
                      placeholder="Enter Year"
                      value={inputYear}
                      onChange={(e) => {
                        const value = e.target.value;

                        // Allow only digits and limit to 4 characters
                        if (/^\d{0,4}$/.test(value)) {
                          setInputYear(value);
                        }
                      }}
                    />
                    <button type="submit">
                      <Icon icon={search} size={20} />
                    </button>
                    {/* <Tippy content="Reset Records">
                      <button
                        type="button"
                        onClick={handleResetFilter}
                        style={{
                          background: "#fff",
                          color: "#6a6c6f",
                          marginLeft: "4px",
                        }}
                      >
                        <Icon icon={refreshCw} size={20} />
                      </button>
                    </Tippy> */}
                  </form>
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => setNewSettingPopup(true)}
                    >
                      New
                    </button>
                  </div>
                </div>
              </div>
              {taxReceiptCalenderConfigurationError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {taxReceiptCalenderConfigurationError}
                </div>
              ) : (
                <Card
                  token={token}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                  setLoadings={setLoadings}
                  viewYear={viewYear}
                  handleEditSettingPopup={handleEditSettingPopup}
                  handleDeleteSettingPopup={handleDeleteSettingPopup}
                />
              )}
            </>
          )}
        </div>
      </div>

      {/* new setting popup  */}
      {newSettingPopup && (
        <NewSettingPopup
          token={token}
          setNewSettingPopup={setNewSettingPopup}
          setViewYear={setViewYear}
          setInputYear={setInputYear}
        />
      )}

      {/* edit setting popup  */}
      {editSettingPopup && (
        <EditSettingPopup
          token={token}
          setEditSettingPopup={setEditSettingPopup}
          editSettingObj={editSettingObj}
        />
      )}

      {/* delete setting popup  */}
      {deleteSettingPopup && (
        <DeleteSettingPopup
          token={token}
          setDeleteSettingPopup={setDeleteSettingPopup}
          deleteSettingObj={deleteSettingObj}
        />
      )}
    </>
  );
};

export default TaxReceiptCalenderComponent;
