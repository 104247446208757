/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MetroSpinner } from "react-spinners-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearDeleteSettingStates,
  deleteSetting,
} from "../../Store/Slices/Sidebar/TaxReceiptCalender/TaxReceiptCalenderSlice";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const DeleteSettingPopup = ({
  token,
  setDeleteSettingPopup,
  deleteSettingObj,
}) => {
  // redux state
  const { deleteSettingLoading, deleteSettingData } = useSelector(
    (state) => state.taxReceiptCalender
  );

  // close modal
  const handleCloseModal = () => {
    if (!deleteSettingLoading) {
      setDeleteSettingPopup(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // clear prev redux state on component mount
  useEffect(() => {
    dispatch(clearDeleteSettingStates());
  }, []);

  // delete setting
  const handleDeleteSetting = () => {
    dispatch(
      deleteSetting({
        token,
        tagNumber: deleteSettingObj.tagNumber,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setDeleteSettingPopup(false);

        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Delete Setting - {deleteSettingObj.year}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <div className="delete-consequences-container">
              <p style={{ color: "#DC3545" }}>
                Are you sure you want to delete this setting?{" "}
              </p>
              <p style={{ color: "#6a6c6f" }}>You cannot undo this action.</p>
            </div>

            {deleteSettingData &&
              deleteSettingData.tagNumber === deleteSettingObj.tagNumber &&
              (deleteSettingData.apiError || deleteSettingData.axiosError) && (
                <div className="error-msg">
                  {deleteSettingData.apiError ? (
                    <>
                      {typeof deleteSettingData.apiError === "object" ? (
                        // Case 1: If apiError is an object
                        Object.keys(deleteSettingData.apiError).map(
                          (field, index) => (
                            <p key={index} style={{ marginBottom: 0 }}>
                              {deleteSettingData.apiError[field][0]}
                            </p>
                          )
                        )
                      ) : (
                        // Case 2: If apiError is a simple string
                        <>{deleteSettingData.apiError}</>
                      )}
                    </>
                  ) : (
                    <>{deleteSettingData.axiosError}</>
                  )}
                </div>
              )}

            <div className="submit-and-cancel-div">
              <button
                type="button"
                className="cancel"
                onClick={handleCloseModal}
              >
                CANCEL
              </button>
              <button
                className="submit delete"
                disabled={deleteSettingLoading}
                onClick={handleDeleteSetting}
              >
                {deleteSettingLoading ? (
                  <MetroSpinner
                    loading={deleteSettingLoading}
                    size={22}
                    color="#fff"
                  />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeleteSettingPopup;
