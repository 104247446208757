/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { readStatusSummary } from "../../Store/Slices/Sidebar/Pledges/PledgesSlice";
import { MetroSpinner } from "react-spinners-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import PieChartComponent from "./PieChartComponent";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const StatusSummary = ({ token, setStatusSummaryModal, statusSummaryObj }) => {
  // redux state
  const { statusSummaryLoading, statusSummaryData, statusSummaryError } =
    useSelector((state) => state.pledge);

  // close modal
  const handleCloseModal = () => {
    if (!statusSummaryLoading) {
      setStatusSummaryModal(false);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // read status summary
  useEffect(() => {
    dispatch(
      readStatusSummary({
        token,
        tagNumber: statusSummaryObj.tagNumber,
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Status Summary - {statusSummaryObj.tagNumber}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {statusSummaryLoading ? (
              <div
                style={{
                  padding: 50 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MetroSpinner
                  size={30}
                  color="#007C16"
                  loading={statusSummaryLoading}
                />
              </div>
            ) : (
              <>
                {statusSummaryError ? (
                  <div
                    style={{
                      padding: 50 + "px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="error-msg">{statusSummaryError}</div>
                  </div>
                ) : (
                  <>
                    {statusSummaryData ? (
                      <div className="graph-container-in-pledge">
                        <PieChartComponent data={statusSummaryData} />
                      </div>
                    ) : (
                      <div
                        className="no-data-found-div"
                        style={{ padding: 50 + "px" }}
                      >
                        <Tippy content="No Data Found" zIndex={100005}>
                          <img src={noDataFound} alt="No Data Found" />
                        </Tippy>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default StatusSummary;
