import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { MDBBtn } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteImage } from "../../Store/Slices/Sidebar/Pledges/PledgesSlice";
import toast from "react-hot-toast";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const PledgeImagesModal = ({
  token,
  setImgModal,
  imgUrl,
  setImgUrl,
  imgName,
  setImgName,
  tagNumber,
  setTagNumber,
}) => {
  // delete image
  const { deleteImageLoading } = useSelector((state) => state.pledge);

  // isLoaded
  const [isLoaded, setIsLoaded] = useState(false);

  // close modal
  const handleCloseModal = () => {
    if (isLoaded && !deleteImageLoading) {
      setImgModal(false);
      setImgUrl(null);
      setImgName(null);
      setTagNumber(null);
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle delete image
  const handleDeleteImage = () => {
    let imageField;

    if (imgName === "Pledged Image") {
      imageField = "fulfillImage";
    } else if (imgName === "Non-Pledged Image") {
      imageField = "emptyImage";
    } else if (imgName === "Banner Image") {
      imageField = "bannerImage";
    }

    dispatch(
      deleteImage({
        token,
        imageField,
        tagNumber,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        setImgModal(false);
        setImgUrl(null);
        setImgName(null);
        setTagNumber(null);

        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/signin");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>{imgName}</div>
            <div
              className="update-widgets-cancel-icon"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body" style={{ padding: "8px" }}>
            <div className="banner-img-container" style={{ padding: "8px" }}>
              <img
                src={imgUrl}
                alt={imgName}
                onLoad={() => {
                  setTimeout(() => {
                    setIsLoaded(true);
                  }, 500);
                }}
                style={{
                  display: isLoaded ? "block" : "none",
                }}
              />
            </div>

            <MDBBtn
              type="button"
              className="mdb-delete-btn"
              onClick={handleDeleteImage}
              disabled={!isLoaded || deleteImageLoading}
            >
              {deleteImageLoading ? "Removing" : "Remove"}
            </MDBBtn>
            <MDBBtn
              type="button"
              className="mdb-cancel-btn"
              onClick={handleCloseModal}
              disabled={!isLoaded || deleteImageLoading}
            >
              CLOSE
            </MDBBtn>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PledgeImagesModal;
