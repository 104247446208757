import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    x: 500,
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const PledgeDetailsDrawer = ({ setPledgeDetailsDrawer, pledgeObj }) => {
  // close modal
  const handleCloseModal = () => {
    setPledgeDetailsDrawer(false);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal drawer"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>{pledgeObj.name}</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body" style={{ padding: "4px" }}>
            <div className="transaction-details" style={{ padding: "8px" }}>
              <div className="notes-div">
                <h5>Description</h5>
                <div style={{ maxHeight: "100%" }}>{pledgeObj.description}</div>
              </div>
            </div>

            <div
              className="reset-and-filter-div"
              style={{ padding: "8px", width: "100%" }}
            >
              <button
                type="button"
                className="reset-fields-btn"
                onClick={() => setPledgeDetailsDrawer(false)}
                style={{ width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PledgeDetailsDrawer;
