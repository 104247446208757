import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  filterTaxReceiptsRecords,
  getAllData,
  sendEmailInTaxReceipts,
} from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import Icon from "react-icons-kit";
import { basic_paperplane } from "react-icons-kit/linea/basic_paperplane";
import { basic_download } from "react-icons-kit/linea/basic_download";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import toast from "react-hot-toast";
import { MetroSpinner } from "react-spinners-kit";
import axios from "axios";
import hostName from "../../config";
import AddDonorModal from "../Donors/AddDonorModal";

// custom styles
const styles = {
  menuPortal: (base) => ({ ...base, zIndex: 10004 }),
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const TaxReceiptTable = ({
  token,
  filteredParams,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  totalPages,
  setTotalPages,
  setPageSize,
  setLoadings,
  year,
  countries,
  usaStates,
  provinces,
  donorSpecific,
  donorSpecificEmail,
}) => {
  // redux state
  const { allData } = useSelector((state) => state.taxReceipts);

  // redux state
  const { sendEmailInTaxReceiptsLoading, sendEmailInTaxReceiptsData } =
    useSelector((state) => state.taxReceipts);

  // per page options array
  const perPageOptions = [
    {
      value: "20",
      label: "20",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "60",
      label: "60",
    },
    {
      value: "80",
      label: "80",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        // get donor specific tax receipts data
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            apiData: {
              email: donorSpecificEmail,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getAllData({
            token,
            page: 1,
            pageSize: value,
            by: "email",
            order: "asc",
            year,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams && donorSpecific === undefined) {
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else if (donorSpecific === true) {
        // get donor specific tax receipts data
        dispatch(
          filterTaxReceiptsRecords({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            apiData: {
              email: donorSpecificEmail,
              by: "email",
            },
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getAllData({
            token,
            page: value,
            pageSize: perPage,
            by: "email",
            order: "asc",
            year,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // email send event
  const handleSendEmail = (data) => {
    dispatch(
      sendEmailInTaxReceipts({
        token,
        tagNumber: data.tagNumber,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error(`${res.payload.apiError}`, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  // download receipt states
  const [downloadReceiptLoading, setDownloadReceiptLoading] = useState(false);
  const [downloadReceiptCode, setDownloadReceiptCode] = useState(null);
  const [tagNumber, setTagNumber] = useState(null);
  const [addDonorStep, setAddDonorStep] = useState(false);

  // handle download receipt
  const handleDownloadReceipt = (data) => {
    if (data.donor) {
      setDownloadReceiptLoading(true);
      setDownloadReceiptCode(data.code);

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      };

      axios
        .get(
          `${hostName}api/admin/v1/taxReceipt/download?code=${data.code}`,
          axiosConfig
        )
        .then((response) => {
          // download pdf
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `taxReceipt.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 401") {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            toast.error(`${error.message}`, {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .finally(() => setDownloadReceiptLoading(false));
    } else {
      setAddDonorStep(true);
      setEmail(data.email);
      setDownloadReceiptCode(data.code);
      setTagNumber(data.tagNumber);
    }
  };

  // email
  const [email, setEmail] = useState("");

  // is tax receipt module true?
  const [isTaxReceiptModule] = useState(true);

  return (
    <>
      {allData?.data?.length > 0 ? (
        <>
          <div className="transactions-table">
            <MDBTable align="middle">
              <MDBTableHead className="thead">
                <tr>
                  {donorSpecific === undefined && <th scope="col">Email</th>}
                  <th scope="col">Year</th>
                  <th scope="col">Receipt Number</th>
                  <th scope="col">Code</th>
                  <th scope="col">Issue Date</th>
                  <th scope="col">State</th>
                  <th scope="col">Actions</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {allData.data.map((data, index) => (
                  <tr
                    key={data.tagNumber}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                    }}
                  >
                    {donorSpecific === undefined && (
                      <td>
                        <p
                          className="fw-normal mb-1"
                          style={{ fontSize: "16px" }}
                        >
                          {data.email ? (
                            <>
                              {data.donor ? (
                                <Tippy
                                  content={`${data.donor.firstName} ${data.donor.lastName}`}
                                  zIndex={10004}
                                >
                                  <Link
                                    to={`/donors/${data.donor.firstName}_${data.donor.lastName}_${data.donor.tagNumber}`}
                                    className="donor-hyper-link"
                                  >
                                    {data.email}
                                  </Link>
                                </Tippy>
                              ) : (
                                <>{data.email}</>
                              )}
                            </>
                          ) : (
                            <>Anonymous</>
                          )}
                        </p>
                      </td>
                    )}
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.year}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.receiptNumber}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.code}
                      </p>
                    </td>
                    <td>
                      <p
                        className="fw-normal mb-1"
                        style={{ fontSize: "16px" }}
                      >
                        {data.issueDate}
                      </p>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Tippy content={data.state} zIndex={10004}>
                        <img
                          src={data.stateImage}
                          alt="status"
                          style={{
                            width: 30 + "px",
                            cursor: "pointer",
                          }}
                        />
                      </Tippy>
                    </td>

                    <td className="icons-td">
                      <div className="actions-icon-container">
                        {/* send email */}
                        {sendEmailInTaxReceiptsData &&
                        sendEmailInTaxReceiptsData.tagNumber ===
                          data.tagNumber &&
                        sendEmailInTaxReceiptsLoading ? (
                          <span className="loader-span">
                            <MetroSpinner
                              loading={sendEmailInTaxReceiptsLoading}
                              color="#007c16"
                              size={20}
                            />
                          </span>
                        ) : (
                          <Tippy content="Send Email" zIndex={10004}>
                            <span
                              onClick={() => handleSendEmail(data)}
                              style={{
                                opacity: data.tagNumber ? 1 : 0.5,
                                pointerEvents: data.tagNumber ? "auto" : "none",
                              }}
                            >
                              <Icon icon={basic_paperplane} size={20} />
                            </span>
                          </Tippy>
                        )}
                        {/* download receipt */}
                        {downloadReceiptLoading &&
                        downloadReceiptCode === data.code ? (
                          <span className="loader-span">
                            <MetroSpinner
                              loading={downloadReceiptLoading}
                              color="#007c16"
                              size={20}
                            />
                          </span>
                        ) : (
                          <Tippy content="Download Receipt" zIndex={10004}>
                            <span
                              onClick={() => handleDownloadReceipt(data)}
                              style={{
                                opacity: data.code ? 1 : 0.5,
                                pointerEvents: data.code ? "auto" : "none",
                              }}
                            >
                              <Icon icon={basic_download} size={20} />
                            </span>
                          </Tippy>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
          </div>
          <div className="transactions-pagination">
            <div className="per-page-and-pagination">
              <div className="per-page">
                <label>Records per page</label>
                <Select
                  value={perPageOptions.find(
                    (option) => option.value === String(perPage)
                  )}
                  onChange={(option) => handlePerPage(option.value)}
                  options={perPageOptions}
                  styles={styles}
                  menuPlacement="top"
                  menuPortalTarget={document.body}
                />
              </div>
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No Tax Receipts Found">
            <img src={noDataFound} alt="No Tax Receipts Found" />
          </Tippy>
        </div>
      )}

      {addDonorStep && (
        <AddDonorModal
          token={token}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          addDonorStep={addDonorStep}
          setAddDonorStep={setAddDonorStep}
          isTaxReceiptModule={isTaxReceiptModule}
          taxReceiptEmail={email}
          setDownloadReceiptLoading={setDownloadReceiptLoading}
          tagNumber={tagNumber}
          downloadReceiptCode={downloadReceiptCode}
        />
      )}
    </>
  );
};

export default TaxReceiptTable;
