import { configureStore } from "@reduxjs/toolkit";
// News
import newsReducer from "./Slices/News/NewsSlice";
// Auth
import userReducer from "./Slices/Auth/UserSlice";
import forgotPasswordReducer from "./Slices/Auth/ForgotPasswordSlice";
import resetPasswordReducer from "./Slices/Auth/ResetPasswordSlice";
// Sidebar
import sidebarReducer from "./Slices/Sidebar/SidebarSlice";
// Analytics
import yearOverYearsReducer from "./Slices/Sidebar/Analytics/YearOverYearsSlice";
import tenderTypesReducer from "./Slices/Sidebar/Analytics/TenderTypesSlice";
import channelTypesReducer from "./Slices/Sidebar/Analytics/ChannelTypesSlice";
import donationsPyramidReducer from "./Slices/Sidebar/Analytics/DonationsPyramidSlice";
import campaignByDayReducer from "./Slices/Sidebar/Analytics/CampaignByDaySlice";
import topDonorReducer from "./Slices/Sidebar/Analytics/TopDonorSlice";
import topDonationsReducer from "./Slices/Sidebar/Analytics/TopDonationsSlice";
import receiptedDonationsReducer from "./Slices/Sidebar/Analytics/ReceiptedDonationsSlice";
import feeSummaryReducer from "./Slices/Sidebar/Analytics/FeeSummarySlice";
// Campaigns
import CampaignReducer from "./Slices/Sidebar/Campaigns/CampaignSlice";
import GetIconsAndNodesReducer from "./Slices/Sidebar/Campaigns/GetIconsAndNodesSlice";
// Dashboard Row 1
import registeredDonorsReducer from "./Slices/Sidebar/Dashboard/RegisteredDonorsSlice";
import noOfFollowersReducer from "./Slices/Sidebar/Dashboard/NoOfFollowersSlice";
import totalTransactionsReducer from "./Slices/Sidebar/Dashboard/TotalTransactionsSlice";
import currentYearTransactionsReducer from "./Slices/Sidebar/Dashboard/CurrentYearTransactionsSlice";
// Dashboard Row 2
import last12MonthsDataReducer from "./Slices/Sidebar/Dashboard/Last12MonthsDataSlice";
import currentYearDonationsReducer from "./Slices/Sidebar/Dashboard/CurrentYearDonationsSlice";
import totalDonationsReducer from "./Slices/Sidebar/Dashboard/TotalDonationsSlice";
import totalRecurringAmountReducer from "./Slices/Sidebar/Dashboard/TotalRecurringAmountSlice";
import activeRecurringReducer from "./Slices/Sidebar/Dashboard/ActiveRecurringSlice";
// Dashboard Row 3
import monthSummaryReducer from "./Slices/Sidebar/Dashboard/MonthSummarySlice";
import daySummaryByCampaignReducer from "./Slices/Sidebar/Dashboard/DaySummaryByCampaignSlice";
// Dashboard Row 4
import campaignSummaryReducer from "./Slices/Sidebar/Dashboard/CampaignSummarySlice";
// Dashboard Row 5
import recurringDetailsReducer from "./Slices/Sidebar/Dashboard/RecurringDetailsSlice";
// import closedRecurringReducer from './Slices/Sidebar/Dashboard/ClosedRecurringSlice';
// Donors
import donorsCrudReducer from "./Slices/Sidebar/Donors/DonorsCrudSlice";
// Donor Details
import donorHighlightsReducer from "./Slices/Sidebar/Donors/DonorHighlightsSlice";
// Wallet
import walletReducer from "./Slices/Wallet/WalletSlice";
// eCheck
import eCheckReducer from "./Slices/Sidebar/ECheck/ECheckSlice";
// Recurring Payments
import recurringPaymentsReducer from "./Slices/Sidebar/RecurringPayments/RecurringPaymentsSlice";
// Transactions
import transactionsSliceReducer from "./Slices/Sidebar/Fundraising/Transactions/GetTransactions";
// Channels
import channelsReducer from "./Slices/Sidebar/Channels/ChannelsSlice";
// Data
import dataManagementReducer from "./Slices/Sidebar/Data/DataSlice";
// organization
import organizationReducer from "./Slices/Sidebar/Organization/OrganizationSlice";
// tax receipt calender configuration
import taxReceiptCalenderReducer from "./Slices/Sidebar/TaxReceiptCalender/TaxReceiptCalenderSlice";
// tax receipts
import taxReceiptsReducer from "./Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
// eTransfer
import eTransferReducer from "./Slices/Sidebar/ETransfer/ETransferSlice";
// pledge
import pledgeReducer from "./Slices/Sidebar/Pledges/PledgesSlice";
// app explorer
import appExplorerReducer from "./Slices/Sidebar/AppExplorer/AppExplorerSlice";
// Media
import widgetsReducer from "./Slices/Sidebar/Media/GetWidgetsSlice";
import mediaReducer from "./Slices/Sidebar/Media/GetMediaSlice";
// Reports
import reportsReducer from "./Slices/Sidebar/Reports/ReportsSlice";
// Users
import crmUsersReducer from "./Slices/Sidebar/Users/CrmUsersSlice";
// Notifications
import notificationsReducer from "./Slices/Sidebar/Notifications/NotificationsSlice";

const store = configureStore({
  reducer: {
    // News
    news: newsReducer,
    // Auth
    user: userReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    // Sidebar
    sidebar: sidebarReducer,
    // Analytics
    yearOverYears: yearOverYearsReducer,
    tenderTypes: tenderTypesReducer,
    channelTypes: channelTypesReducer,
    donationsPyramid: donationsPyramidReducer,
    campaignByDay: campaignByDayReducer,
    topDonor: topDonorReducer,
    topDonations: topDonationsReducer,
    receiptedDonations: receiptedDonationsReducer,
    feeSummary: feeSummaryReducer,
    // Campaigns
    campaign: CampaignReducer,
    getIconsAndNodes: GetIconsAndNodesReducer,
    // Dashboard
    registeredDonors: registeredDonorsReducer,
    noOfFollowers: noOfFollowersReducer,
    totalTransactions: totalTransactionsReducer,
    currentYearTransactions: currentYearTransactionsReducer,
    last12MonthsData: last12MonthsDataReducer,
    totalDonations: totalDonationsReducer,
    currentYearDonations: currentYearDonationsReducer,
    totalRecurringAmount: totalRecurringAmountReducer,
    activeRecurring: activeRecurringReducer,
    monthSummary: monthSummaryReducer,
    daySummaryByCampaign: daySummaryByCampaignReducer,
    campaignSummary: campaignSummaryReducer,
    recurringDetails: recurringDetailsReducer,
    // Donors
    donors: donorsCrudReducer,
    // Donor Details
    donorHighlights: donorHighlightsReducer,
    // Wallet
    wallet: walletReducer,
    // eCheck
    eCheck: eCheckReducer,
    // Recurring Payments
    recurringPayments: recurringPaymentsReducer,
    // Transactions
    transactions: transactionsSliceReducer,
    // Channels
    channels: channelsReducer,
    // Data
    dataManagement: dataManagementReducer,
    // organization
    organization: organizationReducer,
    taxReceiptCalender: taxReceiptCalenderReducer,
    // tax receipts
    taxReceipts: taxReceiptsReducer,
    // eTransfer
    eTransfer: eTransferReducer,
    // pledge control
    pledge: pledgeReducer,
    // app explorer
    appExplorer: appExplorerReducer,
    // Media
    widgets: widgetsReducer,
    media: mediaReducer,
    // Reports
    reports: reportsReducer,
    // Users
    crmUsers: crmUsersReducer,
    // Notifications
    notifications: notificationsReducer,
  },
});

export default store;
