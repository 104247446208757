/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../CSS/Sidebar/ECheck.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getScheduleSummaryData,
  getECheckData,
  filterECheckRecords,
  readListViewData,
  filterListViewRecords,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import { getNodes } from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import { Toaster } from "react-hot-toast";
import Tippy from "@tippyjs/react";
import Icon from "react-icons-kit";
import { filter } from "react-icons-kit/feather/filter";
import { refreshCw } from "react-icons-kit/feather/refreshCw";
import { grid } from "react-icons-kit/feather/grid";
import { list } from "react-icons-kit/feather/list";
import { trash } from "react-icons-kit/feather/trash";
import { creditCard } from "react-icons-kit/feather/creditCard";
import axios from "axios";
import hostName from "../../config";
import ScheduleSummary from "../ECheck/ScheduleSummary";
import ECheckContainer from "../ECheck/ECheckContainer";
import AddECheckTransactionPopup from "../ECheck/AddECheckTransactionPopup";
import EditECheckTransactionPopup from "../ECheck/EditECheckTransactionPopup";
import FilterECheckModal from "../ECheck/FilterECheckModal";
import CloseECheckPopup from "../ECheck/CloseECheckPopup";
import SchedulePopup from "../ECheck/SchedulePopup";
import ECheckTable from "../ECheck/ECheckTable";
import ListViewTransactionDetailsPopup from "../ECheck/ListViewTransactionDetailsPopup";
import FilterListViewModal from "../ECheck/FilterListViewModal";
import CancelPaymentPopup from "../ECheck/CancelPaymentPopup";
import UpdateAmountPopup from "../ECheck/UpdateAmountPopup";
import MassDeletePopup from "../ECheck/MassDeletePopup";
import DetailView from "../ECheck/DetailView";
import MoreDetailsPopup from "../ECheck/MoreDetailsPopup";
import UpdateBankingDetailsPopup from "../ECheck/UpdateBankingDetailsPopup";
import AddPaymentPopup from "../ECheck/AddPaymentPopup";

const ECheckComponent = ({ token }) => {
  // redux state
  const {
    scheduleSummaryLoading,
    scheduleSummaryError,
    eCheckLoading,
    eCheckError,
    listViewLoading,
    listViewError,
  } = useSelector((state) => state.eCheck);

  // states for grid view
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // per page is used outside filter modal
  const [perPage, setPerPage] = useState("10");

  // page size is used in filter modal
  const [pageSize, setPageSize] = useState("10");
  const handlePerPage = (value) => {
    setPageSize(value);
  };

  // states for list view
  const [listViewCurrentPage, setListViewCurrentPage] = useState(1);
  const [listViewTotalPages, setListViewTotalPages] = useState(1);
  // per page is used outside filter modal
  const [listViewPerPage, setListViewPerPage] = useState("20");

  // page size is used in filter modal
  const [listViewPageSize, setListViewPageSize] = useState("20");
  const handlePerPageInListView = (value) => {
    setListViewPageSize(value);
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // schedule summary
  useEffect(() => {
    dispatch(getScheduleSummaryData(token))
      .unwrap()
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
  }, []);

  // layout
  const [layout, setLayout] = useState("grid");

  // filtered flag
  const [filteredFlag, setFilteredFlag] = useState(null);

  // filtered params
  const [filteredParams, setFilteredParams] = useState(null);
  const [listViewFilteredParams, setListViewFilteredParams] = useState(null);

  // filter modal
  const [filterECheckModal, setFilterECheckModal] = useState(false);
  const [filterListViewModal, setFilterListViewModal] = useState(false);

  // get read apis data
  useEffect(() => {
    if (layout === "grid" || layout === "table") {
      // filter echeck data
      if (filteredParams) {
        dispatch(
          filterECheckRecords({
            token,
            page: 1,
            pageSize: filteredParams.pageSize,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        // get all eCheck data
        dispatch(
          getECheckData({
            token,
            page: 1,
            pageSize: "10",
            order: "asc",
            by: "email",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    } else if (layout === "list") {
      // filter listview data
      if (listViewFilteredParams) {
        dispatch(
          filterListViewRecords({
            token,
            page: 1,
            pageSize: listViewFilteredParams.pageSize,
            order: listViewFilteredParams.order,
            apiData: listViewFilteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        // get all listview data
        dispatch(
          readListViewData({
            token,
            page: 1,
            pageSize: "20",
            order: "asc",
            by: "email",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setListViewCurrentPage(res.payload.links.currentPage);
            setListViewPerPage(res.payload.links.perPage);
            setListViewPageSize(res.payload.links.perPage);
            setListViewTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  }, [layout, filteredFlag, filteredParams, listViewFilteredParams]);

  // entity selected
  const [entitySelected, setEntitySelected] = useState([]);
  const handleSelectChange = (selectedOptions) => {
    setEntitySelected(selectedOptions);
  };

  // entity selected
  const [entitySelectedListView, setEntitySelectedListView] = useState([]);
  const handleSelectChangeListView = (selectedOptions) => {
    setEntitySelectedListView(selectedOptions);
  };

  // all api options data states
  const [entityOptions, setEntityOptions] = useState([]);
  const [listViewEntityOptions, setListViewEntityOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [usaStates, setUsaStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [scheduleStatusOptions, setScheduleStatusOptions] = useState([]);
  const [allOptionsLoading, setAllOptionsLoading] = useState(true);

  // getting all options at once
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    Promise.all([
      axios.get(
        `${hostName}api/admin/v1/lov?listName=eCheckFilter`,
        axiosConfig
      ),
      axios.get(
        `${hostName}api/admin/v1/lov?listName=eCheckScheduleFilter`,
        axiosConfig
      ),
      axios.get(`${hostName}api/v1/public/country`),
      axios.get(`${hostName}api/v1/public/state?countryCode=US`),
      axios.get(`${hostName}api/v1/public/state?countryCode=ca`),
      dispatch(getNodes(token)),
      axios.get(
        `${hostName}api/admin/v1/routeLov?routeName=echeck`,
        axiosConfig
      ),
    ])
      .then(
        ([
          gridRes,
          listViewRes,
          countriesRes,
          usaStatesRes,
          provincesRes,
          campaignRes,
          routeRes,
        ]) => {
          // Handle eCheckFilter and eCheckScheduleFilter
          setEntityOptions(gridRes.data.data);
          setListViewEntityOptions(listViewRes.data.data);

          // Handle countries
          const countryOptions = countriesRes.data.map((country) => ({
            value: country.code,
            label: country.name,
          }));
          setCountries(countryOptions);

          // Handle USA states
          const usaStatesOptions = usaStatesRes.data.map((state) => ({
            value: state.code,
            label: state.name,
          }));
          setUsaStates(usaStatesOptions);

          // Handle Canada provinces
          const provincesOptions = provincesRes.data.map((province) => ({
            value: province.code,
            label: province.name,
          }));
          setProvinces(provincesOptions);

          // Handle active campaign options
          setCampaignOptions(campaignRes.payload.active_campaign);
          setAllCampaigns(campaignRes.payload.campaign_tag);

          // Handle frequency and institute options
          setFrequencyOptions(routeRes.data.data.billing_period);
          setInstituteOptions(routeRes.data.data.institute_number);
          setStatusOptions(routeRes.data.data.status);
          setScheduleStatusOptions(routeRes.data.data.schedule_status);
        }
      )
      .catch((error) => {
        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("user");
          navigate("/login");
        }
      })
      .finally(() => setAllOptionsLoading(false));
  }, []);

  // main loadings state
  const [loadings, setLoadings] = useState(true);
  const allLoadings = [
    scheduleSummaryLoading,
    eCheckLoading,
    listViewLoading,
    allOptionsLoading,
  ];
  useEffect(() => {
    const isAnyChildLoading = allLoadings.some((state) => state);
    setLoadings(isAnyChildLoading);
  }, [allLoadings]);

  // new echeck transaction
  const [newECheckTransactionPopup, setNewECheckTransactionPopup] =
    useState(false);
  const [copiedObj, setCopiedObj] = useState(null);

  // add payment
  const [addPaymentPopup, setAddPaymentPopup] = useState(false);
  const [addPaymentObj, setAddPaymentObj] = useState(null);
  const handleAddPaymentPopup = (obj) => {
    setAddPaymentPopup(true);
    setAddPaymentObj(obj);
  };

  // edit echeck transaction
  const [editECheckTransactionPopup, setEditECheckTransactionPopup] =
    useState(false);
  const [editECheckObj, setEditECheckObj] = useState(null);
  const handleEditECheckPopup = (obj) => {
    setEditECheckTransactionPopup(true);
    setEditECheckObj(obj);
  };

  // close echeck popup
  const [closeECheckPopup, setCloseECheckPopup] = useState(false);
  const [closeECheckObj, setCloseECheckObj] = useState(null);
  const handleCloseECheckPopup = (obj) => {
    setCloseECheckPopup(true);
    setCloseECheckObj(obj);
  };

  // cancel payment popup
  const [cancelPaymentPopup, setCancelPaymentPopup] = useState(false);
  const [cancelPaymentObj, setCancelPaymentObj] = useState(null);
  const handleCancelPaymentPopup = (obj) => {
    setCancelPaymentPopup(true);
    setCancelPaymentObj(obj);
  };

  // update amount popup
  const [updateAmountPopup, setUpdateAmountPopup] = useState(false);
  const [updateAmountObj, setUpdateAmountObj] = useState(null);
  const handleUpdateAmountPopup = (obj) => {
    setUpdateAmountPopup(true);
    setUpdateAmountObj(obj);
  };

  // update banking details popup
  const [bankingDetailsPopup, setBankingDetailsPopup] = useState(false);
  const [bankingDetailsObj, setBankingDetailsObj] = useState(null);
  const handleBankingDetailsPopup = (obj) => {
    setBankingDetailsPopup(true);
    setBankingDetailsObj(obj);
  };

  // schedule popup
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [scheduleTag, setScheduleTag] = useState(null);
  const handleSchedulePopup = (tag) => {
    setSchedulePopup(true);
    setScheduleTag(tag);
  };

  // transaction details popup
  const [transactionDetailsPopup, setTransactionDetailsPopup] = useState(false);
  const [transactionObj, setTransactionObj] = useState(null);
  const handleTransactionDetailsPopup = (transaction) => {
    setTransactionDetailsPopup(true);
    setTransactionObj(transaction);
  };

  // more details popup
  const [moreDetailsPopup, setMoreDetailsPopup] = useState(false);
  const [moreDetailsObj, setMoreDetailsObj] = useState(null);
  const handleMoreDetailsPopup = (transaction) => {
    setMoreDetailsPopup(true);
    setMoreDetailsObj(transaction);
  };

  // mass delete popup
  const [massDeletePopup, setMassDeletePopup] = useState(false);

  // approved amount
  const [amount, setAmount] = useState("");
  const handleAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setAmount(newValue);
  };

  // account number
  const [accountNumber, setAccountNumber] = useState("");

  // account holder name
  const [accountHolderName, setAccountHolderName] = useState("");

  // invoice
  const [invoice, setInvoice] = useState("");

  // email
  const [email, setEmail] = useState("");

  // campaignTag
  const [campaignTag, setCampaignTag] = useState([]);
  const handleCampaignTagChange = (item) => {
    if (campaignTag.includes(item)) {
      setCampaignTag(campaignTag.filter((checkedItem) => checkedItem !== item));
    } else {
      setCampaignTag([...campaignTag, item]);
    }
  };

  // billing period
  const [billingPeriod, setBillingPeriod] = useState([]);
  const handleBillingPeriodChange = (item) => {
    if (billingPeriod.includes(item)) {
      setBillingPeriod(
        billingPeriod.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setBillingPeriod([...billingPeriod, item]);
    }
  };

  // created by
  const [createdBy, setCreatedBy] = useState("");

  // status
  const [status, setStatus] = useState([]);
  const handleStatusChange = (item) => {
    if (status.includes(item)) {
      setStatus(status.filter((checkedItem) => checkedItem !== item));
    } else {
      setStatus([...status, item]);
    }
  };

  // institute number
  const [instituteNumber, setInstituteNumber] = useState("");

  // transit number
  const [transitNumber, setTransitNumber] = useState("");

  // comments
  const [comments, setComments] = useState("");

  // sort by
  const [sortBy, setSortBy] = useState({
    value: "email",
    label: "Email",
  });

  // order by
  const [orderBy, setOrderBy] = useState({
    value: "asc",
    label: "Ascending",
  });

  // list view filter states

  // list view account number
  const [listViewAccountNumber, setListViewAccountNumber] = useState("");

  // list view account holder name
  const [listViewAccountHolderName, setListViewAccountHolderName] =
    useState("");

  // campaignTag
  const [campaignTagListView, setCampaignTagListView] = useState([]);
  const handleCampaignTagChangeListView = (item) => {
    if (campaignTagListView.includes(item)) {
      setCampaignTagListView(
        campaignTagListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setCampaignTagListView([...campaignTagListView, item]);
    }
  };

  // charged amount
  const [chargedAmount, setChargedAmount] = useState("");
  const handleChargedAmountChange = (event) => {
    let newValue = event.target.value;
    // Remove any non-numeric and non-dot characters
    newValue = newValue.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const parts = newValue.split(".");
    if (parts.length > 2) {
      // If more than one dot is present, keep only the first part and the first two digits of the second part
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    } else if (parts.length === 2) {
      // If one dot is present, keep only up to two decimal places
      newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    if (isNaN(newValue) || parseFloat(newValue) < 0) {
      newValue = 0;
    }
    setChargedAmount(newValue);
  };

  // invoice
  const [invoiceListView, setInvoiceListView] = useState("");

  // email
  const [emailListView, setEmailListView] = useState("");

  // batch id
  const [batchIdListView, setBatchIdListView] = useState("");

  // billing period
  const [billingPeriodListView, setBillingPeriodListView] = useState([]);
  const handleBillingPeriodChangeListView = (item) => {
    if (billingPeriodListView.includes(item)) {
      setBillingPeriodListView(
        billingPeriodListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setBillingPeriodListView([...billingPeriodListView, item]);
    }
  };

  // institute number
  const [instituteNumberListView, setInstituteNumberListView] = useState("");

  // transit number
  const [transitNumberListView, setTransitNumberListView] = useState("");

  // created by
  const [createdByListView, setCreatedByListView] = useState("");

  // status
  const [statusListView, setStatusListView] = useState([]);
  const handleStatusChangeListView = (item) => {
    if (statusListView.includes(item)) {
      setStatusListView(
        statusListView.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setStatusListView([...statusListView, item]);
    }
  };

  // schedule status
  const [scheduleStatus, setScheduleStatus] = useState([]);
  const handleScheduleStatusChange = (item) => {
    if (scheduleStatus.includes(item)) {
      setScheduleStatus(
        scheduleStatus.filter((checkedItem) => checkedItem !== item)
      );
    } else {
      setScheduleStatus([...scheduleStatus, item]);
    }
  };

  // process date
  const [processDate, setProcessDate] = useState(null);

  // return date
  const [returnDate, setReturnDate] = useState(null);

  // comments
  const [listViewComments, setListViewComments] = useState("");

  // sort by
  const [sortByListView, setSortByListView] = useState({
    value: "email",
    label: "Email",
  });

  // order by
  const [orderByListView, setOrderByListView] = useState({
    value: "asc",
    label: "Ascending",
  });

  // reset filter grid view
  const handleResetFilter = () => {
    if (filteredParams) {
      setLoadings(true);
      setFilteredParams(null);
      setFilterECheckModal(false);
    }
    setEntitySelected([]);
    setAmount("");
    setAccountHolderName("");
    setAccountNumber("");
    setInvoice("");
    setEmail("");
    setCampaignTag([]);
    setInstituteNumber("");
    setTransitNumber("");
    setStatus([]);
    setCreatedBy("");
    setBillingPeriod([]);
    setComments("");
    setPageSize(perPage);
    setSortBy({
      value: "email",
      label: "Email",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset filter list view
  const handleListViewResetFilter = () => {
    if (listViewFilteredParams) {
      setLoadings(true);
      setFilteredFlag(null);
      setListViewFilteredParams(null);
      setFilterListViewModal(false);
    }
    setEntitySelectedListView([]);
    setListViewAccountHolderName("");
    setListViewAccountNumber("");
    setCampaignTagListView([]);
    setChargedAmount("");
    setInvoiceListView("");
    setEmailListView("");
    setBatchIdListView("");
    setBillingPeriodListView([]);
    setInstituteNumberListView("");
    setTransitNumberListView("");
    setCreatedByListView("");
    setStatusListView([]);
    setScheduleStatus([]);
    setProcessDate(null);
    setReturnDate(null);
    setListViewComments("");
    setListViewPageSize(listViewPerPage);
    setSortByListView({
      value: "email",
      label: "Email",
    });
    setOrderByListView({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities
  const resetEntities = () => {
    setEntitySelectedListView([]);
    setListViewAccountHolderName("");
    setListViewAccountNumber("");
    setCampaignTagListView([]);
    setChargedAmount("");
    setInvoiceListView("");
    setEmailListView("");
    setBatchIdListView("");
    setBillingPeriodListView([]);
    setInstituteNumberListView("");
    setTransitNumberListView("");
    setCreatedByListView("");
    setStatusListView([]);
    setScheduleStatus([]);
    setProcessDate(null);
    setReturnDate(null);
    setListViewComments("");
    setListViewPageSize(listViewPerPage);
    setSortByListView({
      value: "email",
      label: "Email",
    });
    setOrderByListView({
      value: "asc",
      label: "Ascending",
    });
  };

  // reset entities table view
  const resetEntitiesTableView = () => {
    setEntitySelected([]);
    setAmount("");
    setAccountHolderName("");
    setAccountNumber("");
    setInvoice("");
    setEmail("");
    setCampaignTag([]);
    setInstituteNumber("");
    setTransitNumber("");
    setStatus([]);
    setCreatedBy("");
    setBillingPeriod([]);
    setComments("");
    setPageSize(perPage);
    setSortBy({
      value: "email",
      label: "Email",
    });
    setOrderBy({
      value: "asc",
      label: "Ascending",
    });
  };

  return (
    <>
      <Toaster />
      <div className="middle-area">
        <div
          className={`middle-content flex-start${loadings ? " height" : ""}`}
        >
          {loadings ? (
            <div className="full-loading-screen">
              <MetroSpinner size={30} color="#007C16" loading={loadings} />
            </div>
          ) : (
            <>
              <h6>eCheck Status Summary</h6>
              {scheduleSummaryError ? (
                <div className="error-msg" style={{ width: "fit-content" }}>
                  {scheduleSummaryError}
                </div>
              ) : (
                <ScheduleSummary
                  filteredFlag={filteredFlag}
                  setFilteredFlag={setFilteredFlag}
                  layout={layout}
                  setLayout={setLayout}
                  setLoadings={setLoadings}
                  setListViewFilteredParams={setListViewFilteredParams}
                  resetEntities={resetEntities}
                />
              )}
              <div className="media-heading-button-container">
                <h6>Records</h6>
                <div className="add-and-filter-btns-div">
                  <div className="action-btns-div">
                    <button
                      type="button"
                      className="action-buttons"
                      onClick={() => {
                        setNewECheckTransactionPopup(true);
                        setCopiedObj(null);
                      }}
                      style={{
                        opacity: layout === "list" ? 0.5 : 1,
                        pointerEvents: layout === "list" ? "none" : "auto",
                      }}
                    >
                      New
                    </button>
                  </div>
                  <div className="filter-and-reset-btns-div">
                    <Tippy content="Summary View">
                      <button
                        className={`filter-media-modal-btn ${
                          layout === "grid" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "grid") {
                            setLoadings(true);
                            setLayout("grid");
                            // setListViewFilteredParams(null);
                            // setFilteredFlag(null);
                            // resetEntities();
                          }
                        }}
                      >
                        <Icon icon={grid} size={24} />
                      </button>
                    </Tippy>
                    <Tippy content="Detail View">
                      <button
                        className={`filter-media-modal-btn reset ${
                          layout === "table" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "table") {
                            setLoadings(true);
                            setLayout("table");
                            // setListViewFilteredParams(null);
                            // setFilteredFlag(null);
                            // resetEntities();
                          }
                        }}
                      >
                        <Icon icon={list} size={24} />
                      </button>
                    </Tippy>
                    <Tippy content="Transaction View">
                      <button
                        className={`filter-media-modal-btn reset ${
                          layout === "list" ? "selected" : ""
                        }`}
                        type="button"
                        onClick={() => {
                          if (layout !== "list") {
                            setLoadings(true);
                            setLayout("list");
                            // setFilteredParams(null);
                            // resetEntitiesTableView();
                          }
                        }}
                      >
                        <Icon icon={creditCard} size={24} />
                      </button>
                    </Tippy>
                    {/* mass delete payment */}
                    <Tippy content="Mass Delete Payment">
                      <button
                        className="filter-media-modal-btn reset"
                        style={{ background: "#dc3546", color: "#fff" }}
                        type="button"
                        onClick={() => setMassDeletePopup(true)}
                      >
                        <Icon icon={trash} size={24} />
                      </button>
                    </Tippy>
                    {/* layout grid filter and reset button */}
                    {(layout === "grid" || layout === "table") && (
                      <>
                        <Tippy content="Filter Records">
                          <button
                            className="filter-media-modal-btn reset"
                            type="button"
                            onClick={() => setFilterECheckModal(true)}
                          >
                            <Icon icon={filter} size={24} />
                          </button>
                        </Tippy>
                        {filteredParams && (
                          <Tippy content="Reset Records">
                            <button
                              className="filter-media-modal-btn reset"
                              type="button"
                              onClick={handleResetFilter}
                            >
                              <Icon icon={refreshCw} size={24} />
                            </button>
                          </Tippy>
                        )}
                      </>
                    )}
                    {/* layout list filter and reset button */}
                    {layout === "list" && (
                      <>
                        <Tippy content="Filter Records">
                          <button
                            className="filter-media-modal-btn reset"
                            type="button"
                            onClick={() => setFilterListViewModal(true)}
                          >
                            <Icon icon={filter} size={24} />
                          </button>
                        </Tippy>
                        {listViewFilteredParams && (
                          <Tippy content="Reset Records">
                            <button
                              className="filter-media-modal-btn reset"
                              type="button"
                              onClick={handleListViewResetFilter}
                            >
                              <Icon icon={refreshCw} size={24} />
                            </button>
                          </Tippy>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* grid layout */}
              {layout === "grid" && (
                <>
                  {eCheckError ? (
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {eCheckError}
                    </div>
                  ) : (
                    <>
                      <div className="media-row">
                        <ECheckContainer
                          token={token}
                          filteredParams={filteredParams}
                          perPage={perPage}
                          setPerPage={setPerPage}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalPages={totalPages}
                          setTotalPages={setTotalPages}
                          setPageSize={setPageSize}
                          handleEditECheckPopup={handleEditECheckPopup}
                          handleCloseECheckPopup={handleCloseECheckPopup}
                          handleSchedulePopup={handleSchedulePopup}
                          handleAddPaymentPopup={handleAddPaymentPopup}
                          setLoadings={setLoadings}
                          setNewECheckTransactionPopup={
                            setNewECheckTransactionPopup
                          }
                          setCopiedObj={setCopiedObj}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {/* table layout (same grid data in table layout) */}
              {layout === "table" && (
                <>
                  {eCheckError ? (
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {eCheckError}
                    </div>
                  ) : (
                    <DetailView
                      token={token}
                      filteredParams={filteredParams}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      setTotalPages={setTotalPages}
                      setPageSize={setPageSize}
                      handleMoreDetailsPopup={handleMoreDetailsPopup}
                      handleSchedulePopup={handleSchedulePopup}
                      handleEditECheckPopup={handleEditECheckPopup}
                      handleCloseECheckPopup={handleCloseECheckPopup}
                      handleAddPaymentPopup={handleAddPaymentPopup}
                      setLoadings={setLoadings}
                      setNewECheckTransactionPopup={
                        setNewECheckTransactionPopup
                      }
                      setCopiedObj={setCopiedObj}
                    />
                  )}
                </>
              )}
              {/* list layout */}
              {layout === "list" && (
                <>
                  {listViewError ? (
                    <div className="error-msg" style={{ width: "fit-content" }}>
                      {listViewError}
                    </div>
                  ) : (
                    <ECheckTable
                      token={token}
                      listViewFilteredParams={listViewFilteredParams}
                      listViewPerPage={listViewPerPage}
                      setListViewPerPage={setListViewPerPage}
                      listViewCurrentPage={listViewCurrentPage}
                      setListViewCurrentPage={setListViewCurrentPage}
                      listViewTotalPages={listViewTotalPages}
                      setListViewTotalPages={setListViewTotalPages}
                      setListViewPageSize={setListViewPageSize}
                      handleCancelPaymentPopup={handleCancelPaymentPopup}
                      handleUpdateAmountPopup={handleUpdateAmountPopup}
                      handleTransactionDetailsPopup={
                        handleTransactionDetailsPopup
                      }
                      handleAddPaymentPopup={handleAddPaymentPopup}
                      handleBankingDetailsPopup={handleBankingDetailsPopup}
                      setLoadings={setLoadings}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* new echeck transaction popup */}
      {newECheckTransactionPopup && (
        <AddECheckTransactionPopup
          token={token}
          setNewECheckTransactionPopup={setNewECheckTransactionPopup}
          countries={countries}
          usaStates={usaStates}
          provinces={provinces}
          campaignOptions={campaignOptions}
          frequencyOptions={frequencyOptions}
          instituteOptions={instituteOptions}
          copiedObj={copiedObj}
          setCopiedObj={setCopiedObj}
        />
      )}
      {/* edit eCheck transaction popup */}
      {editECheckTransactionPopup && (
        <EditECheckTransactionPopup
          token={token}
          setEditECheckTransactionPopup={setEditECheckTransactionPopup}
          editECheckObj={editECheckObj}
          campaignOptions={campaignOptions}
          frequencyOptions={frequencyOptions}
          instituteOptions={instituteOptions}
        />
      )}
      {/* add payment popup */}
      {addPaymentPopup && (
        <AddPaymentPopup
          token={token}
          setAddPaymentPopup={setAddPaymentPopup}
          addPaymentObj={addPaymentObj}
          layout={layout}
        />
      )}
      {closeECheckPopup && (
        <CloseECheckPopup
          token={token}
          setCloseECheckPopup={setCloseECheckPopup}
          closeECheckObj={closeECheckObj}
        />
      )}
      {cancelPaymentPopup && (
        <CancelPaymentPopup
          token={token}
          setCancelPaymentPopup={setCancelPaymentPopup}
          cancelPaymentObj={cancelPaymentObj}
        />
      )}
      {updateAmountPopup && (
        <UpdateAmountPopup
          token={token}
          setUpdateAmountPopup={setUpdateAmountPopup}
          updateAmountObj={updateAmountObj}
        />
      )}
      {bankingDetailsPopup && (
        <UpdateBankingDetailsPopup
          token={token}
          setBankingDetailsPopup={setBankingDetailsPopup}
          bankingDetailsObj={bankingDetailsObj}
          instituteOptions={instituteOptions}
          listViewFilteredParams={listViewFilteredParams}
          setListViewCurrentPage={setListViewCurrentPage}
          setListViewPerPage={setListViewPerPage}
          setListViewPageSize={setListViewPageSize}
          setListViewTotalPages={setListViewTotalPages}
        />
      )}
      {schedulePopup && (
        <SchedulePopup
          token={token}
          setSchedulePopup={setSchedulePopup}
          scheduleTag={scheduleTag}
        />
      )}
      {massDeletePopup && (
        <MassDeletePopup
          token={token}
          setMassDeletePopup={setMassDeletePopup}
          allCampaigns={allCampaigns}
        />
      )}
      {filterECheckModal && (
        <FilterECheckModal
          setFilterECheckModal={setFilterECheckModal}
          setFilteredParams={setFilteredParams}
          entityOptions={entityOptions}
          entitySelected={entitySelected}
          handleSelectChange={handleSelectChange}
          amount={amount}
          handleAmountChange={handleAmountChange}
          accountHolderName={accountHolderName}
          setAccountHolderName={setAccountHolderName}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          campaignTag={campaignTag}
          handleCampaignTagChange={handleCampaignTagChange}
          email={email}
          setEmail={setEmail}
          invoice={invoice}
          setInvoice={setInvoice}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          status={status}
          statusOptions={statusOptions}
          handleStatusChange={handleStatusChange}
          instituteNumber={instituteNumber}
          setInstituteNumber={setInstituteNumber}
          instituteOptions={instituteOptions}
          transitNumber={transitNumber}
          setTransitNumber={setTransitNumber}
          billingPeriod={billingPeriod}
          handleBillingPeriodChange={handleBillingPeriodChange}
          frequencyOptions={frequencyOptions}
          comments={comments}
          setComments={setComments}
          pageSize={pageSize}
          handlePerPage={handlePerPage}
          sortBy={sortBy}
          setSortBy={setSortBy}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          setLoadings={setLoadings}
          handleResetFilter={handleResetFilter}
        />
      )}
      {transactionDetailsPopup && (
        <ListViewTransactionDetailsPopup
          token={token}
          setTransactionDetailsPopup={setTransactionDetailsPopup}
          transactionObj={transactionObj}
        />
      )}
      {moreDetailsPopup && (
        <MoreDetailsPopup
          setMoreDetailsPopup={setMoreDetailsPopup}
          moreDetailsObj={moreDetailsObj}
        />
      )}
      {filterListViewModal && (
        <FilterListViewModal
          setFilterListViewModal={setFilterListViewModal}
          setListViewFilteredParams={setListViewFilteredParams}
          listViewEntityOptions={listViewEntityOptions}
          entitySelectedListView={entitySelectedListView}
          handleSelectChangeListView={handleSelectChangeListView}
          listViewAccountHolderName={listViewAccountHolderName}
          setListViewAccountHolderName={setListViewAccountHolderName}
          listViewAccountNumber={listViewAccountNumber}
          setListViewAccountNumber={setListViewAccountNumber}
          chargedAmount={chargedAmount}
          handleChargedAmountChange={handleChargedAmountChange}
          campaignTagListView={campaignTagListView}
          handleCampaignTagChangeListView={handleCampaignTagChangeListView}
          emailListView={emailListView}
          setEmailListView={setEmailListView}
          invoiceListView={invoiceListView}
          setInvoiceListView={setInvoiceListView}
          batchIdListView={batchIdListView}
          setBatchIdListView={setBatchIdListView}
          createdByListView={createdByListView}
          setCreatedByListView={setCreatedByListView}
          billingPeriodListView={billingPeriodListView}
          handleBillingPeriodChangeListView={handleBillingPeriodChangeListView}
          frequencyOptions={frequencyOptions}
          statusListView={statusListView}
          handleStatusChangeListView={handleStatusChangeListView}
          statusOptions={statusOptions}
          scheduleStatus={scheduleStatus}
          handleScheduleStatusChange={handleScheduleStatusChange}
          scheduleStatusOptions={scheduleStatusOptions}
          instituteNumberListView={instituteNumberListView}
          setInstituteNumberListView={setInstituteNumberListView}
          instituteOptions={instituteOptions}
          transitNumberListView={transitNumberListView}
          setTransitNumberListView={setTransitNumberListView}
          processDate={processDate}
          setProcessDate={setProcessDate}
          returnDate={returnDate}
          setReturnDate={setReturnDate}
          listViewComments={listViewComments}
          setListViewComments={setListViewComments}
          listViewPageSize={listViewPageSize}
          handlePerPageInListView={handlePerPageInListView}
          sortByListView={sortByListView}
          setSortByListView={setSortByListView}
          orderByListView={orderByListView}
          setOrderByListView={setOrderByListView}
          setLoadings={setLoadings}
          setFilteredFlag={setFilteredFlag}
          handleListViewResetFilter={handleListViewResetFilter}
        />
      )}
    </>
  );
};

export default ECheckComponent;
