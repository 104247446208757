import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  filterECheckRecords,
  getECheckData,
} from "../../Store/Slices/Sidebar/ECheck/ECheckSlice";
import Tippy from "@tippyjs/react";
import noDataFound from "../../Images/noDataFound.png";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import Select from "react-select";
import ResponsivePagination from "react-responsive-pagination";
import Icon from "react-icons-kit";
import { messageCircle } from "react-icons-kit/feather/messageCircle";
import { copy } from "react-icons-kit/feather/copy";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const ECheckContainer = ({
  token,
  filteredParams,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages,
  setPageSize,
  handleEditECheckPopup,
  handleCloseECheckPopup,
  handleSchedulePopup,
  handleAddPaymentPopup,
  setLoadings,
  setNewECheckTransactionPopup,
  setCopiedObj,
}) => {
  // redux state
  const { eCheckData } = useSelector((state) => state.eCheck);

  // per page options array
  const perPageOptions = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "50",
      label: "50",
    },
  ];

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // select change
  const handlePerPage = (value) => {
    if (perPage !== value) {
      setPerPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterECheckRecords({
            token,
            page: 1,
            pageSize: value,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getECheckData({
            token,
            page: 1,
            pageSize: value,
            order: "asc",
            by: "email",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  // pagination change
  const handlePageChange = (value) => {
    if (currentPage !== value) {
      setCurrentPage(value);
      setLoadings(true);
      if (filteredParams) {
        dispatch(
          filterECheckRecords({
            token,
            page: value,
            pageSize: perPage,
            order: filteredParams.order,
            apiData: filteredParams.apiData,
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      } else {
        dispatch(
          getECheckData({
            token,
            page: value,
            pageSize: perPage,
            order: "asc",
            by: "email",
          })
        ).then((res) => {
          if (
            res.error &&
            res.error.message === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setCurrentPage(res.payload.links.currentPage);
            setPerPage(res.payload.links.perPage);
            setPageSize(res.payload.links.perPage);
            setTotalPages(res.payload.links.lastPage);
          }
        });
      }
    }
  };

  return (
    <>
      {eCheckData && eCheckData.data.length > 0 ? (
        <>
          {eCheckData.data.map((data) => (
            <div
              className="media-box"
              key={data.tagNumber}
              style={{
                borderTop:
                  data.status === "P"
                    ? "3px solid grey"
                    : data.status === "A"
                    ? "3px solid #007c16"
                    : "3px solid #f2b92c",
                background: "#fff",
                cursor: "pointer",
              }}
            >
              <div className="recurring-row">
                <div className="recurring-leftside">
                  <div className="recurring-campaign-image">
                    <img src={data.campaignImage} alt="data-campaign" />
                  </div>
                  <div className="recurring-campaign-details">
                    <h6>{data.campaign}</h6>
                    <h1>
                      {data.currencySymbol}

                      {data.amount}
                    </h1>
                    <h5>
                      {data.donor ? (
                        <Tippy
                          content={`${data.donor.firstName} ${data.donor.lastName}`}
                        >
                          <Link
                            to={`/donors/${data.donor.firstName}_${data.donor.lastName}_${data.donor.tagNumber}`}
                            className="donor-hyper-link"
                          >
                            {data.email}
                          </Link>
                        </Tippy>
                      ) : (
                        <>{data.email}</>
                      )}
                    </h5>
                  </div>
                </div>
                <div className="recurring-rightside">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {data.comments && (
                      <Tippy content={data.comments}>
                        <span style={{ cursor: "pointer", color: "#6a6a6a" }}>
                          <Icon icon={messageCircle} size={28} />
                        </span>
                      </Tippy>
                    )}
                    <Tippy content={`Copy Record`}>
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#6a6a6a",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          setNewECheckTransactionPopup(true);
                          setCopiedObj(data);
                        }}
                      >
                        <Icon icon={copy} size={28} />
                      </span>
                    </Tippy>
                  </div>
                  <h6>{data.createdBy}</h6>
                  <h1 style={{ textTransform: "uppercase", marginTop: "-6px" }}>
                    {data.billingPeriod}
                  </h1>
                </div>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Status</th>
                      <th scope="col">Invoice</th>
                      <th scope="col">Total Payments</th>
                      <th scope="col">Processed</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">{data.statusText}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.invoice}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.totalPayments}</p>
                      </td>

                      <td>
                        <p className="text-muted mb-0">
                          {data.processedNumber}
                        </p>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Start Date</th>
                      <th scope="col">Billing Expiry</th>
                      <th scope="col">Next Billing</th>
                      <th scope="col">Create Date Time</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">{data.startDate}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.billingExpiry}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.nextBilling}</p>
                      </td>

                      <td>
                        <p className="text-muted mb-0">{data.createDateTime}</p>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div className="media-table-container">
                <MDBTable className="recurring-table">
                  <MDBTableHead className="media-thead">
                    <tr>
                      <th scope="col">Account Title</th>
                      <th scope="col">Bank</th>
                      <th scope="col">Transit#</th>
                      <th scope="col">Account#</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody className="media-body">
                    <tr>
                      <td>
                        <p className="text-muted mb-0">
                          {data.accountHolderName}
                        </p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.instituteName}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.transitNumber}</p>
                      </td>
                      <td>
                        <p className="text-muted mb-0">{data.accountNumber}</p>
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <MDBDropdown dropup group>
                  <MDBDropdownToggle
                    color="success"
                    style={{ background: "#007c16" }}
                  >
                    Actions
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      link
                      onClick={(e) => {
                        e.preventDefault();
                        handleSchedulePopup(data.tagNumber);
                      }}
                    >
                      Schedule
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      style={{
                        pointerEvents:
                          data.status === "A" &&
                          data.billingPeriod !== "One Time"
                            ? "auto"
                            : "none",
                        opacity:
                          data.status === "A" &&
                          data.billingPeriod !== "One Time"
                            ? 1
                            : 0.5,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddPaymentPopup(data);
                      }}
                    >
                      Add Payment
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      style={{
                        pointerEvents: data.status === "C" ? "none" : "auto",
                        opacity: data.status === "C" ? 0.5 : 1,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleEditECheckPopup(data);
                      }}
                    >
                      Edit
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      link
                      style={{
                        pointerEvents: data.status === "A" ? "auto" : "none",
                        opacity: data.status === "A" ? 1 : 0.5,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseECheckPopup(data);
                      }}
                    >
                      Close
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </div>
            </div>
          ))}
          <div className="per-page-and-pagination">
            <div className="per-page">
              <label>Records per page</label>
              <Select
                value={perPageOptions.find(
                  (option) => option.value === String(perPage)
                )}
                onChange={(option) => handlePerPage(option.value)}
                options={perPageOptions}
                styles={styles}
                menuPlacement="top"
              />
            </div>
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <div className="no-data-found-div">
          <Tippy content="No eCheck Transactions Found">
            <img src={noDataFound} alt="No eCheck Transactions Found" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default ECheckContainer;
