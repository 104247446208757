import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import hostName from "../../../../../config";
import axios from "axios";

// get transactions --- array of objects
export const getTransactions = createAsyncThunk(
  "transactions/getTransactions",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/transaction?page=${singleObj.page}&pageSize=${singleObj.pageSize}&by=${singleObj.by}&order=${singleObj.order}`,
      axiosConfig
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// filter records
export const filterTransactionRecords = createAsyncThunk(
  "transaction/filterTransactionRecords",
  async (singleObj) => {
    const { apiData, token, page, pageSize, order } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "approved_amount",
      "card_account",
      "campaign_tag",
      "email",
      "invoice",
      "user_notes",
      "approval_code",
      "node_type",
      "node_name",
      "card_type",
      "payment_processor",
      "to_date",
      "from_date",
      "issue_tax_receipt",
      "transaction_type",
      "by",
    ];

    Object.keys(apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = apiData[key];
      }
    });

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/transaction?page=${page}&pageSize=${pageSize}&order=${order}`,
      {
        params: filteredData,
        ...axiosConfig,
      }
    );
    const response = await request.data;
    return { data: response.data, links: response.links };
  }
);

// export records
export const exportTransactionRecords = createAsyncThunk(
  "transactions/exportTransactionRecords",
  async (singleObj) => {
    const { filteredParams, token } = singleObj;

    // Include only the parameters present in the apiData
    const filteredData = {};
    const allowedKeys = [
      "approved_amount",
      "card_account",
      "campaign_tag",
      "email",
      "invoice",
      "user_notes",
      "approval_code",
      "node_type",
      "node_name",
      "card_type",
      "payment_processor",
      "to_date",
      "from_date",
      "issue_tax_receipt",
      "transaction_type",
      "by",
    ];

    Object.keys(filteredParams.apiData).forEach((key) => {
      if (allowedKeys.includes(key)) {
        filteredData[key] = filteredParams.apiData[key];
      }
    });

    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/transaction/data/export`,
      {
        params: filteredData,
        ...axiosConfig,
        responseType: "arraybuffer",
      }
    );
    const response = await request.data;
    return response;
  }
);

// get nodes
export const getNodes = createAsyncThunk("nodes/getNodes", async (token) => {
  const axiosConfig = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const request = await axios.get(
    `${hostName}api/admin/v1/routeLov?routeName=transaction`,
    axiosConfig
  );
  const response = await request.data;
  return response.data;
});

// get donors by email
export const getDonorsByEmail = createAsyncThunk(
  "donors/getDonorsByEmail",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/donor/list?email=${singleObj.email}`,
      axiosConfig
    );
    const response = await request.data;
    return response;
  }
);

// send email
export const sendEmail = createAsyncThunk(
  "transactions/sendEmail",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        tagNumber: singleObj.tagNumber,
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/transaction/sendInvoice`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        error: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        error: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// change email
export const changeEmail = createAsyncThunk(
  "transactions/changeEmail",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        tagNumber: singleObj.tagNumber,
      };
      if (singleObj.email) {
        requestedData.email = singleObj.email;
      }
      const request = await axios.post(
        `${hostName}api/admin/v1/transaction/updateEmail`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        updatedTransaction: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        updatedTransaction: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// download receipt
export const downloadTransactionReceipt = createAsyncThunk(
  "transactions/downloadTransactionReceipt",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.get(
        `${hostName}api/admin/v1/transaction/reports/receipt?pTransactionTag=${singleObj.transactionTag}`,
        { ...axiosConfig, responseType: "arraybuffer" }
      );
      const response = await request.data;
      return {
        file: response,
        successMsg: "Receipt has been downloaded successfully",
        error: null,
      };
    } catch (err) {
      return {
        file: null,
        successMsg: null,
        error: err.message,
      };
    }
  }
);

// delete transaction
export const deleteTransaction = createAsyncThunk(
  "donors/deleteTransaction",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.delete(
        `${hostName}api/admin/v1/transaction/${singleObj.tagNumber}`,
        axiosConfig
      );
      const response = await request.data;
      return {
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add manual transaction object
export const addManualTransaction = createAsyncThunk(
  "transactions/addManualTransaction",
  async (singleObj) => {
    try {
      const data = {
        tenderType: singleObj.tenderType,
        campaignTag: singleObj.campaignTag,
        amount: singleObj.amount,
      };
      if (singleObj.userNotes) {
        data.userNotes = singleObj.userNotes;
      }
      if (singleObj.email) {
        data.email = singleObj.email;
      }
      if (singleObj.dateTime) {
        data.transactionDate = singleObj.dateTime;
      }
      if (singleObj.tenderType === "cheque") {
        data.bankName = singleObj.bankName;
        data.accountNumber = singleObj.accountNumber;
        data.chequeNumber = singleObj.chequeNumber;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/transaction`,
        data,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (err) {
      return {
        data: null,
        successMsg: null,
        apiError: err.response.data.messageDetails,
        axiosError: err.message,
      };
    }
  }
);

// edit manual transaction object
export const editManualTransaction = createAsyncThunk(
  "transactions/editManualTransaction",
  async (singleObj) => {
    try {
      const data = {
        tenderType: singleObj.tenderType,
        campaignTag: singleObj.campaignTag,
        amount: singleObj.amount,
      };
      if (singleObj.userNotes) {
        data.userNotes = singleObj.userNotes;
      }
      if (singleObj.email) {
        data.email = singleObj.email;
      }
      if (singleObj.dateTime) {
        data.transactionDate = singleObj.dateTime;
      }
      if (singleObj.tenderType === "cheque") {
        data.bankName = singleObj.bankName;
        data.accountNumber = singleObj.accountNumber;
        data.chequeNumber = singleObj.chequeNumber;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.put(
        `${hostName}api/admin/v1/transaction/${singleObj.tagNumber}`,
        data,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (err) {
      return {
        data: null,
        successMsg: null,
        apiError: err.response.data.messageDetails,
        axiosError: err.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// add card transaction object
export const addCardTransaction = createAsyncThunk(
  "transactions/addCardTransaction",
  async (singleObj) => {
    try {
      const data = {
        email: singleObj.email,
        campaignTag: singleObj.campaignTag,
        amount: singleObj.amount,
        source: singleObj.source,
        cardHolderName: singleObj.cardHolderName,
        cardNumber: singleObj.cardNumber,
        expiryYear: singleObj.expiryYear,
        expiryMonth: singleObj.expiryMonth,
        billingPeriod: singleObj.billingPeriod,
        noOfRecurring: singleObj.noOfRecurrings,
        fee: singleObj.fee,
      };
      if (singleObj.userNotes) {
        data.userNotes = singleObj.userNotes;
      }
      if (singleObj.startDate) {
        data.startDate = singleObj.startDate;
      }
      if (singleObj.source === "WALLET") {
        data.cardId = singleObj.cardId;
        data.function = singleObj.function;
      } else if (singleObj.source === "CARD") {
        data.ccv = singleObj.cvc;
        data.saveCard = singleObj.saveCard;
      }

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };

      const request = await axios.post(
        `${hostName}api/admin/v1/transaction/cardTransaction`,
        data,
        axiosConfig
      );

      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        cardId: singleObj.cardId,
      };
    } catch (err) {
      return {
        data: null,
        successMsg: null,
        apiError: err.response.data.messageDetails,
        axiosError: err.message,
        cardId: singleObj.cardId,
      };
    }
  }
);

// update campaign
export const updateCampaign = createAsyncThunk(
  "transactions/updateCampaign",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/transaction/updateCampaign`,
        {
          tagNumber: singleObj.tagNumber,
          campaignTag: singleObj.campaignTag,
        },
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      return {
        data: null,
        successMsg: null,
        apiError: error.response.data.messageDetails,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// get refundable transactions
export const getRefundableTransactions = createAsyncThunk(
  "transactions/getRefundableTransactions",
  async (singleObj) => {
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${singleObj.token}`,
      },
    };
    const request = await axios.get(
      `${hostName}api/admin/v1/refund?tagNumber=${singleObj.tagNumber}`,
      axiosConfig
    );
    const response = await request.data;
    return response;
  }
);

// refund amount
export const refundAmount = createAsyncThunk(
  "transactions/refundAmount",
  async (singleObj) => {
    try {
      const data = {
        tagNumber: singleObj.tagNumber,
        userNotes: singleObj.userNotes,
        amount: singleObj.amount,
      };

      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const request = await axios.post(
        `${hostName}api/admin/v1/refund`,
        data,
        axiosConfig
      );
      const response = await request.data;
      return {
        data: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
        tagNumber: singleObj.tagNumber,
      };
    } catch (error) {
      let apiError;
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        data: null,
        successMsg: null,
        apiError: apiError,
        axiosError: error.message,
        tagNumber: singleObj.tagNumber,
      };
    }
  }
);

// replace email
export const replaceEmail = createAsyncThunk(
  "transactions/replaceEmail",
  async (singleObj) => {
    try {
      const axiosConfig = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${singleObj.token}`,
        },
      };
      const requestedData = {
        oldEmail: singleObj.oldEmail,
        newEmail: singleObj.newEmail,
      };

      const request = await axios.put(
        `${hostName}api/admin/v1/donor/replaceEmail`,
        requestedData,
        axiosConfig
      );
      const response = await request.data;
      return {
        updatedTransactions: response.data,
        successMsg: response.messageDetails,
        apiError: null,
        axiosError: null,
      };
    } catch (error) {
      let apiError;
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "object"
      ) {
        apiError = error.response.data.errors;
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.messageDetails
      ) {
        apiError = error.response.data.messageDetails;
      } else {
        apiError = error.response.data.message;
      }
      return {
        updatedTransactions: null,
        successMsg: null,
        apiError,
        axiosError: error.message,
      };
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    // get transactions
    transactionsLoading: false,
    transactionsData: null,
    transactionsError: null,
    // export transactions
    exportTransactionLoading: false,
    exportTransactionError: null,
    // get nodes
    nodesLoading: false,
    nodesData: null,
    nodesError: null,
    // get donors by email
    getDonorsByEmailLoading: false,
    getDonorsByEmailData: null,
    getDonorsByEmailError: null,
    // send email
    sendEmailLoading: false,
    sendEmailData: null,
    // change email
    changeEmailLoading: false,
    changeEmailData: null,
    // replace email
    replaceEmailLoading: false,
    replaceEmailData: null,
    // download receipt
    downloadReceiptLoading: false,
    downloadReceiptData: null,
    // delete transaction
    deleteTransactionLoading: false,
    deleteTransactionData: null,
    // add manual transaction
    manualTransactionLoading: false,
    manualTransactionData: null,
    // edit manual transactions
    editManualTransactionLoading: false,
    editManualTransactionData: null,
    // card transaction
    cardTransactionLoading: false,
    cardTransactionData: null,
    // update campaign
    updateCampaignLoading: false,
    updateCampaignData: null,
    // refundable transactions
    refundableTransactionsLoading: false,
    refundableTransactionsData: null,
    refundableTransactionsError: null,
    // refund amount
    refundAmountLoading: false,
    refundAmountData: null,
  },
  reducers: {
    clearPrevManualTransactionData: (state) => {
      state.manualTransactionData = null;
    },
    clearPrevTransactionData: (state) => {
      // card
      state.cardTransactionData = null;
    },
    clearRefundAmountData: (state) => {
      state.refundAmountData = null;
    },
    clearReplaceEmailStates: (state) => {
      state.replaceEmailData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get transactions
      .addCase(getTransactions.pending, (state) => {
        state.transactionsLoading = true;
        state.transactionsData = null;
        state.transactionsError = null;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactionsLoading = false;
        state.transactionsData = action.payload;
        state.transactionsError = null;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.transactionsLoading = false;
        state.transactionsData = null;
        state.transactionsError = action.error.message;
      })
      // filter transaction records
      .addCase(filterTransactionRecords.pending, (state) => {
        state.transactionsLoading = true;
        state.transactionsData = null;
        state.transactionsError = null;
      })
      .addCase(filterTransactionRecords.fulfilled, (state, action) => {
        state.transactionsLoading = false;
        state.transactionsData = action.payload;
        state.transactionsError = null;
      })
      .addCase(filterTransactionRecords.rejected, (state, action) => {
        state.transactionsLoading = false;
        state.transactionsData = null;
        state.transactionsError = action.error.message;
      })
      // export transaction records
      .addCase(exportTransactionRecords.pending, (state) => {
        state.exportTransactionLoading = true;
        state.exportTransactionError = null;
      })
      .addCase(exportTransactionRecords.fulfilled, (state) => {
        state.exportTransactionLoading = false;
        state.exportTransactionError = null;
      })
      .addCase(exportTransactionRecords.rejected, (state, action) => {
        state.exportTransactionLoading = false;
        state.exportTransactionError = action.error.message;
      })
      // get nodes
      .addCase(getNodes.pending, (state) => {
        state.nodesLoading = true;
        state.nodesData = null;
        state.nodesError = null;
      })
      .addCase(getNodes.fulfilled, (state, action) => {
        state.nodesLoading = false;
        state.nodesData = action.payload;
        state.nodesError = null;
      })
      .addCase(getNodes.rejected, (state, action) => {
        state.nodesLoading = false;
        state.nodesData = null;
        state.nodesError = action.error.message;
      })
      // get donors by email
      .addCase(getDonorsByEmail.pending, (state) => {
        state.getDonorsByEmailLoading = true;
        state.getDonorsByEmailData = null;
        state.getDonorsByEmailError = null;
      })
      .addCase(getDonorsByEmail.fulfilled, (state, action) => {
        state.getDonorsByEmailLoading = false;
        state.getDonorsByEmailData = action.payload;
        state.getDonorsByEmailError = null;
      })
      .addCase(getDonorsByEmail.rejected, (state, action) => {
        state.getDonorsByEmailLoading = false;
        state.getDonorsByEmailData = null;
        state.getDonorsByEmailError = action.error.message;
      })
      // send email
      .addCase(sendEmail.pending, (state, action) => {
        state.sendEmailLoading = true;
        state.sendEmailData = action.meta.arg;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.sendEmailLoading = false;
        state.sendEmailData = action.payload;
      })
      // change email
      .addCase(changeEmail.pending, (state) => {
        state.changeEmailLoading = true;
        state.changeEmailData = null;
      })
      .addCase(changeEmail.fulfilled, (state, action) => {
        state.changeEmailLoading = false;
        state.changeEmailData = action.payload;
        const { updatedTransaction } = action.payload;
        if (updatedTransaction) {
          const { transactionTag } = updatedTransaction;
          const transactionIndex =
            state.transactionsData.data.transactions.findIndex(
              (transaction) => transaction.transactionTag === transactionTag
            );

          if (transactionIndex !== -1) {
            state.transactionsData.data.transactions[transactionIndex] =
              updatedTransaction;
          }
        }
      })
      // download receipt
      .addCase(downloadTransactionReceipt.pending, (state, action) => {
        state.downloadReceiptLoading = true;
        state.downloadReceiptData = action.meta.arg;
      })
      .addCase(downloadTransactionReceipt.fulfilled, (state, action) => {
        state.downloadReceiptLoading = false;
        state.downloadReceiptData = action.payload;
      })
      // delete transaction
      .addCase(deleteTransaction.pending, (state) => {
        state.deleteTransactionLoading = true;
        state.deleteTransactionData = null;
      })
      .addCase(deleteTransaction.fulfilled, (state, action) => {
        state.deleteTransactionLoading = false;
        state.deleteTransactionData = action.payload;
        const { axiosError, tagNumber } = action.payload;
        if (!axiosError) {
          const filterData = state.transactionsData.data.transactions.filter(
            (transaction) => transaction.transactionTag !== tagNumber
          );
          state.transactionsData.data.transactions = filterData;
        }
      })
      // add manual transaction
      .addCase(addManualTransaction.pending, (state) => {
        state.manualTransactionLoading = true;
        state.manualTransactionData = null;
      })
      .addCase(addManualTransaction.fulfilled, (state, action) => {
        state.manualTransactionLoading = false;
        state.manualTransactionData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.transactionsData.data.transactions.unshift(data);
        }
      })
      // replace email
      .addCase(replaceEmail.pending, (state) => {
        state.replaceEmailLoading = true;
        state.replaceEmailData = null;
      })
      .addCase(replaceEmail.fulfilled, (state, action) => {
        state.replaceEmailLoading = false;
        state.replaceEmailData = action.payload;
      })
      // edit manual transaction
      .addCase(editManualTransaction.pending, (state) => {
        state.editManualTransactionLoading = true;
        state.editManualTransactionData = null;
      })
      .addCase(editManualTransaction.fulfilled, (state, action) => {
        state.editManualTransactionLoading = false;
        state.editManualTransactionData = action.payload;
        const { data } = action.payload;
        if (data) {
          const { transactionTag } = data;
          const index = state.transactionsData.data.transactions.findIndex(
            (transaction) => transaction.transactionTag === transactionTag
          );
          if (index !== -1) {
            state.transactionsData.data.transactions[index] = data;
          }
        }
      })
      // add card transaction
      .addCase(addCardTransaction.pending, (state) => {
        state.cardTransactionLoading = true;
        state.cardTransactionData = null;
      })
      .addCase(addCardTransaction.fulfilled, (state, action) => {
        state.cardTransactionLoading = false;
        state.cardTransactionData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.transactionsData.data.transactions.unshift(data);
        }
      })
      // update campaign
      .addCase(updateCampaign.pending, (state) => {
        state.updateCampaignLoading = true;
        state.updateCampaignData = null;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.updateCampaignLoading = false;
        state.updateCampaignData = action.payload;
        const { data, tagNumber } = action.payload;
        if (data) {
          const objIndex = state.transactionsData.data.transactions.findIndex(
            (obj) => obj.transactionTag === tagNumber
          );
          if (objIndex !== -1) {
            state.transactionsData.data.transactions[objIndex] = data;
          }
        }
      })
      // refund able transactions
      .addCase(getRefundableTransactions.pending, (state) => {
        state.refundableTransactionsLoading = true;
        state.refundableTransactionsData = null;
        state.refundableTransactionsError = null;
      })
      .addCase(getRefundableTransactions.fulfilled, (state, action) => {
        state.refundableTransactionsLoading = false;
        state.refundableTransactionsData = action.payload;
        state.refundableTransactionsError = null;
      })
      .addCase(getRefundableTransactions.rejected, (state, action) => {
        state.refundableTransactionsLoading = false;
        state.refundableTransactionsData = null;
        state.refundableTransactionsError = action.error.message;
      })
      // refund amount
      .addCase(refundAmount.pending, (state) => {
        state.refundAmountLoading = true;
        state.refundAmountData = null;
      })
      .addCase(refundAmount.fulfilled, (state, action) => {
        state.refundAmountLoading = false;
        state.refundAmountData = action.payload;
        const { data } = action.payload;
        if (data) {
          state.transactionsData.data.transactions.unshift(data);
        }
      });
  },
});

export default transactionsSlice.reducer;
export const {
  clearPrevManualTransactionData,
  clearPrevTransactionData,
  clearRefundAmountData,
  clearReplaceEmailStates
} = transactionsSlice.actions;
