/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { info } from "react-icons-kit/feather/info";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearReplaceEmailStates,
  replaceEmail,
} from "../../Store/Slices/Sidebar/Fundraising/Transactions/GetTransactions";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-hot-toast";
import Tippy from "@tippyjs/react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Swal from "sweetalert2";

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// Function to generate 8 random characters (capital letters, small letters, and numbers)
const generateRandomCode = () => {
  const characters = "0123456789";
  let result = "";
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const ReplaceEmailPopup = ({ token, setReplaceEmailPopup }) => {
  // redux state
  const { replaceEmailLoading, replaceEmailData } = useSelector(
    (state) => state.transactions
  );

  // state
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [randomChars, setRandomChars] = useState("");

  // close modal
  const handleCloseModal = () => {
    if (!replaceEmailLoading) {
      setReplaceEmailPopup(false);
    }
  };

  // security code
  const [securityCode, setSecurityCode] = useState(generateRandomCode());

  // submit btn
  const isButtonDisabled = randomChars !== securityCode;

  // dispatch and navigae
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // reset prev error in case any
  useEffect(() => {
    dispatch(clearReplaceEmailStates());
  }, []);

  // form submit
  const handleReplaceEmail = (e) => {
    e.preventDefault();
    // Show a warning using SweetAlert
    Swal.fire({
      title: "Warning!",
      html: `
      <p style="color: #6a6c6f; font-size: 16px; line-height: 1.5" }}>You are about to replace the email address for this user across multiple records in the system. 
      This change will affect all transaction history.</p>
      <p style="color: #DC3545; font-size: 16px; line-height: 1.5; font-weight: 600">Please double-check the new email address before proceeding, as this action cannot be undone.</p>
      <p style="color: #565656; font-size: 20px; line-height: 1.5; font-weight: bold">Do you wish to proceed?</p>
    `,
      icon: "warning",
      customClass: {
        container: "custom-sweetalert-container",
        confirmButton: "custom-danger-button",
      },
      didOpen: () => {
        // Set the zIndex for the SweetAlert container
        document.querySelector(
          ".custom-sweetalert-container"
        ).style.zIndex = 999999;
      },
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: "BACK",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          replaceEmail({
            token,
            oldEmail,
            newEmail,
          })
        ).then((res) => {
          if (res.payload.successMsg) {
            setReplaceEmailPopup(false);
            toast.success(`${res.payload.successMsg}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (
            res.payload.axiosError === "Request failed with status code 401"
          ) {
            localStorage.removeItem("user");
            navigate("/login");
          } else {
            setSecurityCode(generateRandomCode());
            setRandomChars("");
          }
        });
      }
    });
  };

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>Replace Email</div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            <form onSubmit={handleReplaceEmail}>
              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>
                    Old Email{" "}
                    <Tippy
                      content="Enter the current email address you wish to change."
                      zIndex={100001}
                    >
                      <span>
                        <Icon icon={info} size={18} />
                      </span>
                    </Tippy>
                  </label>
                  <input
                    type="email"
                    className="edit-media-inputs"
                    value={oldEmail}
                    onChange={(e) => setOldEmail(e.target.value)}
                    placeholder="Old Email"
                    required
                    autoComplete="old-email-alias"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>
                    New Email{" "}
                    <Tippy
                      content="Enter the new email address you want to use."
                      zIndex={100001}
                    >
                      <span>
                        <Icon icon={info} size={18} />
                      </span>
                    </Tippy>
                  </label>
                  <input
                    type="email"
                    className="edit-media-inputs"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    placeholder="New Email"
                    required
                    autoComplete="new-email-alias"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-3">
                <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                  <label>
                    Security Code{" "}
                    <Tippy
                      content="Enter the security code mentioned below in the security code field."
                      zIndex={100001}
                    >
                      <span>
                        <Icon icon={info} size={18} />
                      </span>
                    </Tippy>
                  </label>
                  <div
                    style={{
                      padding: "10px",
                      fontSize: "18px",
                      fontFamily: "'Courier New', Courier, monospace",
                      background: "#f3f3f3",
                      color: "#333",
                      letterSpacing: "0.3em",
                      textAlign: "center",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      marginTop: "5px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {securityCode}
                  </div>
                  <input
                    type="text"
                    className="edit-media-inputs"
                    value={randomChars}
                    onChange={(e) => setRandomChars(e.target.value)}
                    placeholder="Security Code "
                    required
                    autoComplete="new-random-chars"
                  />
                </MDBCol>
              </MDBRow>

              {replaceEmailData &&
                (replaceEmailData.apiError || replaceEmailData.axiosError) && (
                  <div className="error-msg">
                    {replaceEmailData.apiError ? (
                      <>
                        {typeof replaceEmailData.apiError === "object" ? (
                          // Case 1: If apiError is an object
                          Object.keys(replaceEmailData.apiError).map(
                            (field, index) => (
                              <p key={index} style={{ marginBottom: 0 }}>
                                {replaceEmailData.apiError[field][0]}
                              </p>
                            )
                          )
                        ) : (
                          // Case 2: If apiError is a simple string
                          <>{replaceEmailData.apiError}</>
                        )}
                      </>
                    ) : (
                      <>{replaceEmailData.axiosError}</>
                    )}
                  </div>
                )}

              <div className="submit-and-cancel-div">
                <button
                  type="button"
                  className="cancel"
                  onClick={handleCloseModal}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="submit addModal"
                  disabled={replaceEmailLoading || isButtonDisabled}
                  style={{
                    pointerEvents: isButtonDisabled ? "none" : "auto",
                    opacity: isButtonDisabled ? 0.5 : 1,
                  }}
                >
                  {replaceEmailLoading ? (
                    <MetroSpinner
                      loading={replaceEmailLoading}
                      size={22}
                      color="#fff"
                    />
                  ) : (
                    "REPLACE"
                  )}
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ReplaceEmailPopup;
