/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "react-icons-kit";
import { x } from "react-icons-kit/feather/x";
import { info } from "react-icons-kit/feather/info";
import Tippy from "@tippyjs/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  clearPrevIssueReceiptData,
  issueReceipts,
} from "../../Store/Slices/Sidebar/TaxReceipts/TaxReceiptsSlice";
import Select from "react-select";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { MetroSpinner } from "react-spinners-kit";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

// backshadow variants
const backVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

// modal variants
const modalVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const IssueReceiptsPopup = ({
  token,
  setIssueReceiptsPopup,
  yearOptions,
  templateOptions,
  variableOptions,
}) => {
  // redux state
  const { issueReceiptsLoading, issueReceiptsData } = useSelector(
    (state) => state.taxReceipts
  );

  // close modal
  const handleCloseModal = () => {
    if (!issueReceiptsLoading) {
      setIssueReceiptsPopup(false);
    }
  };

  // states
  const [year, setYear] = useState((new Date().getFullYear() - 1).toString()); // current year 2022
  const [template, setTemplate] = useState({});
  const [prefix, setPrefix] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState(null);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [warningStep, setWarningStep] = useState(false);
  const [variable, setVariable] = useState("");

  // update subject and message on template change
  useEffect(() => {
    if (template.title !== undefined && template.body !== undefined) {
      setSubject(template.title);
      setMessage(template.body);
      setSubjectError(null);
      setMessageError(null);
    } else {
      setSubject("");
      setMessage("");
    }
  }, [template]);

  // handle year change
  const handleYearChange = (value) => {
    if (year !== value) {
      setYear(value);
    }
  };

  const handleTemplateChange = (option) => {
    if (
      message === undefined ||
      !message ||
      message.trim() === "" ||
      message.trim() === "<p><br></p>"
    ) {
      // No warning, directly update template
      setTemplate(option);
    } else {
      // Show a warning using SweetAlert
      Swal.fire({
        title: "Warning!",
        text: "Continuing with this action will clear your existing message. Do you want to proceed?",
        icon: "warning",
        customClass: {
          container: "custom-sweetalert-container",
          confirmButton: "custom-ok-button",
        },
        didOpen: () => {
          // Set the zIndex for the SweetAlert container
          document.querySelector(
            ".custom-sweetalert-container"
          ).style.zIndex = 999999;
        },
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          setTemplate(option);
        }
      });
    }
  };

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle send me a sample
  const handleSendMeASample = () => {
    // Validate subject
    if (subject === undefined || !subject || subject.trim() === "") {
      setSubjectError("Subject cannot be empty");
      return; // Stop further processing
    } else {
      setSubjectError(null);
    }

    // Validate message
    if (
      message === undefined ||
      !message ||
      message.trim() === "" ||
      message.trim() === "<p><br></p>"
    ) {
      setMessageError("Message cannot be empty");
      return; // Stop further processing
    } else {
      setMessageError(null);
    }

    // dispatch action after validation
    dispatch(
      issueReceipts({
        token,
        year,
        prefix,
        subject,
        message,
        sample: true,
      })
    ).then((res) => {
      if (res.payload.successMsg) {
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  // show warning step
  const handleWarningStep = () => {
    // Validate subject
    if (subject === undefined || !subject || subject.trim() === "") {
      setSubjectError("Subject cannot be empty");
      return; // Stop further processing
    } else {
      setSubjectError(null);
    }

    // Validate message
    if (
      message === undefined ||
      !message ||
      message.trim() === "" ||
      message.trim() === "<p><br></p>"
    ) {
      setMessageError("Message cannot be empty");
      return; // Stop further processing
    } else {
      setMessageError(null);
    }

    // set warning step to true
    setWarningStep(true);
  };

  // send email
  const handleSendEmail = () => {
    dispatch(
      issueReceipts({
        token,
        year,
        prefix,
        subject,
        message,
        sample: false,
      })
    ).then((res) => {
      if (!res.payload.axiosError) {
        setIssueReceiptsPopup(false);
        toast.success(`${res.payload.successMsg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        res.payload.axiosError === "Request failed with status code 401"
      ) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
  };

  // Function to insert a variable at the cursor position
  const insertVariable = (variable) => {
    setVariable(variable);
    const editor = quillRef.current.getEditor();
    const cursorPosition = editor.getSelection(true).index;
    editor.insertText(cursorPosition, variable);
  };

  // Quill reference for accessing the editor instance
  const quillRef = useRef();

  // clear previously issued receipt data, it will clear error on opening popup
  useEffect(() => {
    dispatch(clearPrevIssueReceiptData());
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        className="update-widgets-backdrop"
        variants={backVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="update-widgets-modal media extra-width"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="update-widgets-modal-header">
            <div>
              {!warningStep
                ? "Issue Receipts"
                : "Please Verify Charity Details Before Issuing Tax Receipts"}
            </div>
            <div
              className="cancel-widget-update-icon"
              onClick={handleCloseModal}
            >
              <Icon icon={x} size={16} />
            </div>
          </div>
          <div className="update-widgets-modal-body">
            {!warningStep ? (
              <>
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                    <label>Year</label>
                    <Select
                      value={yearOptions.find(
                        (option) => option.value === year
                      )}
                      onChange={(option) => {
                        handleYearChange(option ? option.value : null);
                      }}
                      options={yearOptions}
                      styles={styles}
                      menuPosition="fixed"
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                    <label>Prefix</label>
                    <input
                      type="text"
                      className="edit-media-inputs"
                      placeholder="Prefix"
                      value={prefix}
                      onChange={(e) => setPrefix(e.target.value)}
                      maxLength={10}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                    <label>
                      Template{" "}
                      <Tippy
                        content="Choose a template for email subject and message"
                        zIndex={100001}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <Icon icon={info} size={16} />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      value={templateOptions.find(
                        (option) => option.value === template.value
                      )}
                      onChange={handleTemplateChange}
                      options={templateOptions}
                      styles={styles}
                      menuPosition="fixed"
                    />
                  </MDBCol>
                  <MDBCol className="col-12 col-md-6 mb-2 mb-md-0">
                    <label>
                      Variables{" "}
                      <Tippy
                        content="Insert variables in the email message"
                        zIndex={100001}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <Icon icon={info} size={16} />
                        </span>
                      </Tippy>
                    </label>
                    <Select
                      value={variableOptions.find(
                        (option) => option.value === variable
                      )}
                      onChange={(option) => {
                        insertVariable(option ? option.value : "");
                      }}
                      options={variableOptions}
                      styles={styles}
                      menuPosition="fixed"
                      isClearable
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label>Subject</label>
                    <input
                      className="edit-media-inputs"
                      placeholder="Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      maxLength={1000}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol className="col-12 col-md-12 mb-2 mb-md-0">
                    <label>Message</label>
                    <ReactQuill
                      ref={quillRef}
                      theme="snow"
                      value={message}
                      onChange={(value) => setMessage(value)}
                      modules={{
                        toolbar: {
                          container: [
                            [{ header: [1, 2, false] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [{ list: "ordered" }, { list: "bullet" }],
                            ["link", "image"],
                            ["clean"],
                            [{ script: "sub" }, { script: "super" }],
                          ],
                        },
                      }}
                    />
                  </MDBCol>
                </MDBRow>

                {issueReceiptsData &&
                  issueReceiptsData.sample &&
                  issueReceiptsData.apiError && (
                    <div className="error-msg">
                      {issueReceiptsData.apiError}
                    </div>
                  )}
                {subjectError && (
                  <div className="error-msg">{subjectError}</div>
                )}
                {messageError && (
                  <div className="error-msg">{messageError}</div>
                )}

                <div className="submit-and-cancel-div tax-receipts">
                  <button
                    type="button"
                    className="cancel"
                    onClick={handleCloseModal}
                  >
                    CANCEL
                  </button>
                  <div className="send-me-email-sample-div">
                    <button
                      type="button"
                      className="submit addModal"
                      disabled={issueReceiptsLoading}
                      onClick={handleSendMeASample}
                    >
                      {issueReceiptsLoading &&
                      issueReceiptsData &&
                      issueReceiptsData.sample ? (
                        <MetroSpinner
                          size={22}
                          color="#fff"
                          loading={issueReceiptsLoading}
                        />
                      ) : (
                        "SEND ME A SAMPLE"
                      )}
                    </button>
                    <button
                      type="button"
                      className="submit addModal"
                      disabled={issueReceiptsLoading}
                      onClick={handleWarningStep}
                    >
                      SEND EMAIL
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <p>
                  Before proceeding to issue all tax receipts, it is crucial to
                  ensure that all charity details and signatures are accurate
                  and up to date. Incorrect information on tax receipts can lead
                  to significant issues for both the donors and your
                  organization.
                </p>
                <p>Please take a moment to verify the following:</p>
                <ul>
                  <li>Charity name and registration number.</li>
                  <li>Contact details of the charity.</li>
                  <li>Authorized signatures.</li>
                </ul>
                <p>
                  To verify these details,{" "}
                  <Link
                    to="/manage/organization"
                    style={{ color: "#007c16" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click Here
                  </Link>
                </p>
                <h6 style={{ fontWeight: 600, color: "#6a6a6a" }}>
                  Are you ready to proceed with issuing the tax receipts?
                </h6>

                {issueReceiptsData &&
                  !issueReceiptsData.sample &&
                  issueReceiptsData.apiError && (
                    <div className="error-msg">
                      {issueReceiptsData.apiError}
                    </div>
                  )}

                <div className="submit-and-cancel-div">
                  <button
                    type="button"
                    className="cancel"
                    onClick={() => setWarningStep(false)}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="submit addModal"
                    disabled={issueReceiptsLoading}
                    onClick={handleSendEmail}
                  >
                    {issueReceiptsLoading &&
                    issueReceiptsData &&
                    !issueReceiptsData.sample ? (
                      <MetroSpinner
                        size={22}
                        color="#fff"
                        loading={issueReceiptsLoading}
                      />
                    ) : (
                      "PROCEED"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default IssueReceiptsPopup;
